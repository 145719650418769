import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Link, useNavigate } from 'react-router-dom';
import api from '../utils/api';
import Cookies from 'js-cookie';
import './LoginModal.css';

const LoginModal = ({ isOpen, onClose, setUserId, setLevel }) => {
  const [userId, setUserIdLocal] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const rememberedUserId = localStorage.getItem('rememberedUserId');
    if (rememberedUserId) {
      setUserIdLocal(rememberedUserId);
    }
  }, []);

  const requiredFields = [
    { field: 'userId', message: '아이디를 입력해주세요' },
    { field: 'password', message: '비밀번호를 입력해주세요' },
  ];

  const validateFields = () => {
    const newErrors = {};
    requiredFields.forEach(({ field, message }) => {
      if (!eval(field).trim()) {
        newErrors[field] = message;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = async () => {
    const newErrors = {};
    requiredFields.forEach(({ field, message }) => {
      if (!eval(field).trim()) {
        newErrors[field] = message;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      const errorMessages = Object.values(newErrors).join('\n');
      alert(errorMessages);
      setErrors(newErrors);
      return;
    }

    try {
      const response = await api.post('/deeps_user/auth/', {
        user_id: userId,
        password: password
      });

      if (response.data.code === '0000') {
        const accessToken = response.data.token.access;
        const refreshToken = response.data.token.refresh;
        const level = response.data.user.level;

        // localStorage 초기화
        localStorage.clear();

        localStorage.setItem('accessToken', accessToken);
        Cookies.set('refreshToken', refreshToken, { secure: true, sameSite: 'Strict' });
        localStorage.setItem('userId', userId);
        localStorage.setItem('rememberedUserId', userId);
        localStorage.setItem("level", level);

        setUserId(userId);
        setLevel(level);
        onClose();
        navigate('/');  // 메인 페이지로 이동
      } else {
        console.log("로그인 실패:", response.data.message);
        alert("로그인에 실패했습니다. 아이디와 비밀번호를 확인해주세요.");
      }
    } catch (error) {
      console.error("로그인 중 오류 발생:", error);
      alert("로그인에 실패했습니다. 아이디와 비밀번호를 확인해주세요.");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="login_ReactModal__Content"
      overlayClassName="login_ReactModal__Overlay"
    >
      <div className="login-modal-content">
        <div className="login-modal-header">
          <h2>Login</h2>
          <button className="close-button" onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>
        <div className="login-modal-body">
          <input
            type="text"
            value={userId}
            onChange={(e) => setUserIdLocal(e.target.value)}
            placeholder="Enter your ID"
            className="modal-input"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your Password"
            className="modal-input"
          />
          <Link to="/signup" onClick={onClose} className="signup-link">회원가입</Link>
        </div>
        <div className="login-modal-footer">
          <button onClick={handleLogin} className="modal-button">
            <i className="fa fa-sign-in-alt"></i> Login
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
