import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navigation.css';

function Navigation({ onLoginClick, userId, level }) {
  return (
    <nav className="navigation">
      <div className="nav-container">
        <Link to="/" className="nav-logo">
          <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="DEEPS" />
        </Link>
        <ul className="nav-links">
          <li>
            <Link to="/sports-learning" className="nav-link-item">
              <i className="fas fa-dumbbell"></i>
              <span>스포츠 학습</span>
            </Link>
          </li>
          <li>
            <Link to="/travel_product" className="nav-link-item">
              <i className="fas fa-plane"></i>
              <span>여행 상품</span>
            </Link>
          </li>
        </ul>
        <div className="nav-auth">
          {userId ? (
            <Link to="/user-profile" className="nav-user">
              <div className="user-icon-level">
                <span className="user-level">LV. {level}</span>
              </div>
              <span className="user-name">{userId}</span>
            </Link>
          ) : (
            <button className="nav-login" onClick={onLoginClick}>
              <i className="fas fa-sign-in-alt"></i>
              <span>로그인</span>
            </button>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
