import React from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './common.css';
//import './EquipmentPurchaseInput.css';

const EquipmentPurchaseInput = () => {
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image', 'video'],
        ['clean']
      ]
    },
    formats: ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'link', 'image', 'video'],
    theme: 'snow',
  });

  // 사용자 정의 이미지 업로드 처리
  React.useEffect(() => {
    if (quill) {
      quill.getModule('toolbar').addHandler('image', () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = () => {
          const file = input.files[0];
          const formData = new FormData();
          formData.append('file', file);

          fetch('https://example.com/upload-image', {
            method: 'POST',
            body: formData,
          })
            .then(response => response.json())
            .then(result => {
              const url = result.url;
              const range = quill.getSelection();
              quill.insertEmbed(range.index, 'image', url);
            })
            .catch(error => {
              console.error('Image upload failed:', error);
            });
        };
      });
    }
  }, [quill]);

  return (
    <div className="input-container">
      <h2 className="input-section-title">장비 구매 입력</h2>

      <div className="input-form-group">
        <div className="input-row">
          <div className="input-item">
            <label>장비</label>
            <input type="text" className="equipmentinput-form-control" placeholder="장비명을 입력하세요" />
          </div>
          <div className="input-item">
            <label>브랜드</label>
            <input type="text" className="equipmentinput-form-control" placeholder="브랜드명을 입력하세요" />
          </div>
        </div>

        <div className="input-row">
          <div className="input-item">
            <label>판매자</label>
            <input type="text" className="equipmentinput-form-control" placeholder="판매자명을 입력하세요" />
          </div>
          <div className="input-item">
            <label>가격 (원)</label>
            <input type="number" className="equipmentinput-form-control" placeholder="가격을 입력하세요" />
          </div>
        </div>

        <div className="input-row">
          <div className="input-item">
            <label>커버<br/>이미지</label>
            <input type="file" className="equipmentinput-form-control" />
          </div>
        </div>

        <div className="equipmentinput-form-group-detailed">
          <label>상세 설명</label>
          <div>
            <div ref={quillRef} />
          </div>
        </div>

        <div className="equipmentinput-div-input-save">
          <button className="equipmentinput-btn btn-primary save-button">저장</button>
        </div>
      </div>
    </div>
  );
};

export default EquipmentPurchaseInput;
