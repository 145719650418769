import React from 'react';
import './ApplyHistory.css';
import { ArrowForwardIos } from '@mui/icons-material';

const DetailTimeline = ({ date, status, title, description, extra }) => {
    return (
        <div className="timeline-item glassmorphism">
            <div className="timeline-icon">
                <div className="icon-placeholder">사진</div>
            </div>
            <div className="timeline-content">
                <div className="timeline-header">
                    <span className="date">{date}</span>
                    <span className="status">{status}</span>
                </div>
                <h3>{title}</h3>
                <p>{description}</p>
                <p>{extra}</p>
            </div>
            <div className="timeline-action">
                <ArrowForwardIos fontSize="large" />
            </div>
        </div>
    );
};

const ApplyHistory = () => {
    const sportsData = [
        { date: '24.08.23 14:38', status: '신청완료', title: '프리다이빙 - 입문+초급(Day 1)', description: '잠실 종합운동장 / 10시~13시', extra: '강사: 럭키' },
        { date: '25.08.23 10:00', status: '신청완료', title: '스쿠버다이빙 - 중급 과정', description: '부산 해양센터 / 14시~17시', extra: '강사: 박수영' },
        { date: '26.08.23 09:00', status: '신청완료', title: '머메이드 - 고급 과정', description: '강릉 해양센터 / 12시~15시', extra: '강사: 이지아' },
    ];

    const travelData = [
        { date: '24.08.23 14:38', status: '신청완료', title: '필리핀 아닐라오 투어', description: '2024.10.23 ~ 2024.10.28', extra: '인솔자: 럭키, 공보경, 에디, 김연수, 김학봉, 윤훈빈' },
        { date: '01.09.23 10:00', status: '신청완료', title: '제주도 프리다이빙 투어', description: '2024.09.10 ~ 2024.09.15', extra: '인솔자: 정현우, 박지수' },
        { date: '15.09.23 09:00', status: '신청완료', title: '팔라우 스쿠버다이빙 투어', description: '2024.12.01 ~ 2024.12.06', extra: '인솔자: 이승준, 강수현' },
    ];

    const equipmentData = [
        { date: '24.08.23 14:38', status: '결제완료', title: '트루 다이브 고탄성 슈트', description: '가격: 498,000원 -> 425,000원', extra: '판매자: 딥스프리다이빙' },
        { date: '01.09.23 14:00', status: '결제완료', title: 'AquaLung 레귤레이터', description: '가격: 620,000원 -> 590,000원', extra: '판매자: 다이빙샵' },
        { date: '15.09.23 11:30', status: '결제완료', title: 'Cressi 프리다이빙 핀', description: '가격: 200,000원', extra: '판매자: 프리다이빙몰' },
    ];

    return (
        <div className="apply-history">
            <h2>신청 내역</h2>

            <section>
                <h3>스포츠 강습</h3>
                <div className="timeline">
                    {sportsData.map((item, index) => (
                        <DetailTimeline
                            key={index}
                            date={item.date}
                            status={item.status}
                            title={item.title}
                            description={item.description}
                            extra={item.extra}
                        />
                    ))}
                </div>
            </section>

            <section>
                <h3>여행 상품</h3>
                <div className="timeline">
                    {travelData.map((item, index) => (
                        <DetailTimeline
                            key={index}
                            date={item.date}
                            status={item.status}
                            title={item.title}
                            description={item.description}
                            extra={item.extra}
                        />
                    ))}
                </div>
            </section>

            <section>
                <h3>장비 구매</h3>
                <div className="timeline">
                    {equipmentData.map((item, index) => (
                        <DetailTimeline
                            key={index}
                            date={item.date}
                            status={item.status}
                            title={item.title}
                            description={item.description}
                            extra={item.extra}
                        />
                    ))}
                </div>
            </section>
        </div>
    );
};

export default ApplyHistory;
