import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './common.css';
import './TravelProductsDetail.css';
import api from '../utils/api';

const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;

const TravelProductsDetail = () => {
  const location = useLocation();
  const { product } = location.state;
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    phone: ''
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [userLevel, setUserLevel] = useState('');
  const [showFullNames, setShowFullNames] = useState(false);

  useEffect(() => {
    setUserLevel(localStorage.getItem("level") || '');
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      fetchUserInfo(storedUserId);
    }
  }, []);

  const fetchUserInfo = async (userId) => {
    try {
      const response = await api.get(`deeps_user/api/v1/user-info/${userId}`);
      setUserInfo({
        name: response.data.name,
        email: response.data.user_email,
        phone: response.data.hp
      });
    } catch (error) {
      console.error('사용자 정보 가져오기 실패:', error);
    }
  };

  const checkNameVisibility = async () => {
    userLevel = localStorage.getItem("level")
    return userLevel === "11" || userLevel === "22";
  };

  // 로그인 상태 확인 함수
  const checkLoginStatus = () => {
    const userId = localStorage.getItem('userId');
    return !!userId;
  };

  // 로그인 확인 및 경고 메시지 표시 함수
  const showLoginWarning = () => {
    alert('로그인이 필요합니다. 메인 페이지로 이동합니다.');
    navigate('/');
  };

  const handleApply = async () => {
    if (!checkLoginStatus()) {
      showLoginWarning();
      return;
    }
    const response = await createPaymentInfo(product, userInfo);
    if (response) {
      const paymentInfo = {
        Moid: `travel_${product.id}_${localStorage.getItem('userId')}`,
        GoodsName: product.name,
        // 일단 테스트로 100원만 결제되도록 설정
        //Amt: '100',
        Amt: product.price,
        ReturnURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_BASE_PATH}/payment-result/`,
        BuyerName: userInfo.name,
        BuyerEmail: userInfo.email,
        BuyerTel: userInfo.phone,
      };

      if (typeof window.initializePayment === 'function') {
        window.initializePayment(paymentInfo);
      } else {
        console.error('initializePayment function is not defined');
        alert('오류가 발생하였습니다. 다시 시도해주세요.');
      }
    }
  };

  const createPaymentInfo = async (product, userInfo) => {
    try {
      const response = await api.post(`${API_BASE_PATH}/pre-payment-check-travel/`, {
        product_id: product.id,
        selected_price: product.price,
      });

      if (response.data.success) {
        if (response.data.code === '1002' || response.data.code === '1005') {
          handleErrorCode(response.data.code, response.data.message);
          return null;
        }
        return response;
      } else {
        console.error('서버 응답 실패:', response.data.message);
        handleErrorCode(response.data.code, response.data.message);
        return null;
      }
    } catch (error) {
      console.error('서버 요청 중 오류 발생:', error);
      alert('서버 통신 중 오류가 발생했습니다. 다시 시도해주세요.');
      return null;
    }
  };

  const handleErrorCode = (code, message) => {
    switch (code) {
      case "1001":
        alert("Authorization header missing");
        break;
      case "1002":
        alert("세션이 만료되었습니다. 다시 로그인해주세요.");
        localStorage.removeItem('userId');
        navigate('/');
        break;
      case "1003":
        alert("Invalid token");
        break;
      case "1004":
        alert(`Missing required field: ${message}`);
        break;
      case "1005":
        alert("이미 등록되어 있습니다.");
        break;
      case "1006":
        alert(`An error occurred: ${message}`);
        break;
      default:
        alert('결제 준비 중 오류가 발생했습니다. 다시 시도해주세요.');
    }
  };

  const handleParticipantClick = (participant) => {
    setSelectedParticipant(participant);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedParticipant(null);
  };

  const canViewParticipantInfo = () => {
    return userLevel === "11" || userLevel === "22";
  };

  const renderParticipantName = (participant) => {
    if (showFullNames) {
      return participant;
    } else {
      return `${participant.charAt(0)}OO`;
    }
  };

  const handleApplyWithoutPayment = async () => {
    if (!checkLoginStatus()) {
      showLoginWarning();
      return;
    }
    const response = await createPaymentInfo(product, userInfo);
    if (response) {
      alert('대기자 신청이 성공적으로 완료되었습니다.');
      navigate('/');
    }
  };

  const handleAlipay = async () => {
    if (!checkLoginStatus()) {
      showLoginWarning();
      return;
    }
    const response = await createPaymentInfo(product, userInfo);
    if (response) {
      const alipayPaymentInfo = {
        Moid: `alipay_${product.id}_${localStorage.getItem('userId')}`,
        GoodsName: product.name,
        //Amt: '100', // 테스트용 금액 설정
        Amt: product.price,
        ReturnURL: `${window.env.REACT_APP_API_URL}${window.env.REACT_APP_API_BASE_PATH}/payment-result-ali/`,
        RetryURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_BASE_PATH}/payment-retry-ali/`,
        BuyerName: userInfo.name,
        BuyerEmail: userInfo.email,
        BuyerTel: userInfo.phone,
      };

      if (typeof window.initializeAlipay === 'function') {
        window.initializeAlipay(alipayPaymentInfo);
      } else {
        console.error('initializeAlipay 함수가 정의되지 않았습니다.');
        alert('오류가 발생하였습니다. 다시 시도해주세요.');
        return null;
      }
    }
  };

  return (
    <div className="detail-container">
      <img src={product.cover_image} alt={product.name} className="detail-image" />
      <div className="detail-content">
        <h2>{product.name}</h2>
        <p className="detail-subcontent">여행 기간: {product.start_date} ~ {product.end_date}</p>
        <p className="detail-subcontent">가이드: {product.guide}</p>
        <p className="detail-subcontent">금액: {parseInt(product.price).toLocaleString()}원</p>
        <p className="detail-subcontent">
          참가자: {product.paid_participants && product.paid_participants.length > 0 ? 
            product.paid_participants.map((participant, index) => (
              <React.Fragment key={participant.id}>
                {canViewParticipantInfo() ? (
                  <span 
                    onClick={() => handleParticipantClick(participant)}
                    style={{cursor: 'pointer', textDecoration: 'underline'}}
                  >
                    {participant.name}
                  </span>
                ) : (
                  <span>{`${participant.name.charAt(0)}OO`}</span>
                )}
                {index < product.paid_participants.length - 1 ? ', ' : ''}
              </React.Fragment>
            )) : '없음'}
        </p>
        <p className="detail-subcontent">
          대기자: {product.unpaid_participants && product.unpaid_participants.length > 0 ? 
            product.unpaid_participants.map((participant, index) => (
              <React.Fragment key={participant.id}>
                {canViewParticipantInfo() ? (
                  <span 
                    onClick={() => handleParticipantClick(participant)}
                    style={{cursor: 'pointer', textDecoration: 'underline'}}
                  >
                    {participant.name}
                  </span>
                ) : (
                  <span>{`${participant.name.charAt(0)}OO`}</span>
                )}
                {index < product.unpaid_participants.length - 1 ? ', ' : ''}
              </React.Fragment>
            )) : '없음'}
        </p>
        <div className="additional-info">
          <div dangerouslySetInnerHTML={{ __html: product.detailed_content }} />
        </div>
        <div className="d-flex justify-content-between">
          <button 
            className={`btn ${product.paid_participants.length >= product.max_participants ? 'btn-secondary wait-button' : 'btn-primary apply-button'}`} 
            onClick={product.paid_participants.length >= product.max_participants ? handleApplyWithoutPayment : handleApply}
          >
            {product.paid_participants.length >= product.max_participants 
              ? '대기자 신청하기' 
              : `${parseInt(product.price).toLocaleString()}원 결제하기`}
          </button>
          {product.paid_participants.length < product.max_participants && (
            <button className="btn btn-secondary alipay-button" onClick={handleAlipay}>
              <i className="fab fa-alipay mr-2"></i>알리페이 결제
            </button>
          )}
        </div>

        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header>
            <Modal.Title className="modal-schedule-title">참가자 정보</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedParticipant && (
              <div className="participant-info">
                <table>
                  <tbody>
                    <tr>
                      <th>이름</th>
                      <td>{selectedParticipant.name}</td>
                    </tr>
                    <tr>
                      <th>성별</th>
                      <td>{selectedParticipant.gender === 'M' ? '남자' : '여자'}</td>
                    </tr>
                    <tr>
                      <th>이메일</th>
                      <td>{selectedParticipant.user_email}</td>
                    </tr>
                    <tr>
                      <th>전화번호</th>
                      <td>{selectedParticipant.hp}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={handleCloseModal}>
              닫기
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default TravelProductsDetail;
