import React from 'react';
import './TravelCard.css';

const TravelCard = ({ title, dateRange, guides, onClick, maxParticipants, paidCount, unpaidCount }) => {
  return (
    <div className="travel-card" onClick={onClick}>
      <div className="travel-card-content">
        <h3 className="travel-card-title">{title}</h3>
        <p className="travel-card-date">{dateRange}</p>
        <p className="travel-card-guides">인솔자: {guides.join(', ')}</p>
        <p className="schedule-details">신청: {paidCount} / {maxParticipants} (대기 {unpaidCount})</p>

      </div>
    </div>
  );
};

export default TravelCard;