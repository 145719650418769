import React, { useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import './common.css';
//import './EquipmentPurchase.css';

const EquipmentPurchase = () => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

  const items = [
    {
      id: 1,
      image: '/images/글라이드.jpg',
      title: '트루다이브 고탄성 수트',
      seller: '딥스프리다이빙',
      date: '2024.02.29',
      description: `◆ 브랜드 : 트루다이브 (TRUDIVE)

      ◆ 용도 : 슈트

      ◆ 특징 : 탄성이 좋아서 다른 재질보다 입고 벗기가 편한 소재의 슈트입니다. (야마모토)
      후드가 있는 프리다이빙 전용 슈트이며, 글라이드 스킨 재질이라 물 유입이 적고, 저항이 없습니다.
      제주도 투어 또는 울릉도 투어 등 국내투어 가실 땐 슈트가 필수이며, 미리 구매하시는 걸 추천드립니다.

      ◆ 색상 : 블랙, 블루, 그린, 레드, 그레이, 퍼플, 실버, 골드

      ◆ 재질 : 안감 - 클로즈셀 / 겉감 - 글라이드 스킨

      ◆ 가격 : 498,000원 -> 425,000원

      * 딥스 프리다이빙 공보경강사님이 엠버서더로 있는 브랜드이기에 저렴하게 구매가능한 브랜드입니다.

      ◆ 구매 댓글 예시 : 홍길동 / 010-1234-5678 / 블랙 (사이즈는 연락시 말씀주세요)
      (또는 담당강사님께 상담 가능)

      ◆ 수령방법 : 배송
      주문 제작 및 해외배송 이기에 재고 있으면 당일 배송, 
      재고가 없으면 4주정도 소요됩니다.`,
    },
    {
        id: 2,
        image: '/images/글라이드.jpg',
        title: '트루다이브 수트',
        seller: '딥스프리다이빙',
        date: '2024.02.29',
        description: `◆ 브랜드 : 트루다이브 (TRUDIVE)
  
        ◆ 용도 : 슈트
  
        ◆ 특징 : 탄성이 좋아서 다른 재질보다 입고 벗기가 편한 소재의 슈트입니다. (야마모토)
        후드가 있는 프리다이빙 전용 슈트이며, 글라이드 스킨 재질이라 물 유입이 적고, 저항이 없습니다.
        제주도 투어 또는 울릉도 투어 등 국내투어 가실 땐 슈트가 필수이며, 미리 구매하시는 걸 추천드립니다.
  
        ◆ 색상 : 블랙, 블루, 그린, 레드, 그레이, 퍼플, 실버, 골드
  
        ◆ 재질 : 안감 - 클로즈셀 / 겉감 - 글라이드 스킨
  
        ◆ 가격 : 498,000원 -> 425,000원
  
        * 딥스 프리다이빙 공보경강사님이 엠버서더로 있는 브랜드이기에 저렴하게 구매가능한 브랜드입니다.
  
        ◆ 구매 댓글 예시 : 홍길동 / 010-1234-5678 / 블랙 (사이즈는 연락시 말씀주세요)
        (또는 담당강사님께 상담 가능)
  
        ◆ 수령방법 : 배송
        주문 제작 및 해외배송 이기에 재고 있으면 당일 배송, 
        재고가 없으면 4주정도 소요됩니다.`,
      },{
        id: 3,
        image: '/images/글라이드.jpg',
        title: '트루다이브 고탄성 수트',
        seller: '딥스프리다이빙',
        date: '2024.02.29',
        description: `◆ 브랜드 : 트루다이브 (TRUDIVE)
  
        ◆ 용도 : 슈트
  
        ◆ 특징 : 탄성이 좋아서 다른 재질보다 입고 벗기가 편한 소재의 슈트입니다. (야마모토)
        후드가 있는 프리다이빙 전용 슈트이며, 글라이드 스킨 재질이라 물 유입이 적고, 저항이 없습니다.
        제주도 투어 또는 울릉도 투어 등 국내투어 가실 땐 슈트가 필수이며, 미리 구매하시는 걸 추천드립니다.
  
        ◆ 색상 : 블랙, 블루, 그린, 레드, 그레이, 퍼플, 실버, 골드
  
        ◆ 재질 : 안감 - 클로즈셀 / 겉감 - 글라이드 스킨
  
        ◆ 가격 : 498,000원 -> 425,000원
  
        * 딥스 프리다이빙 공보경강사님이 엠버서더로 있는 브랜드이기에 저렴하게 구매가능한 브랜드입니다.
  
        ◆ 구매 댓글 예시 : 홍길동 / 010-1234-5678 / 블랙 (사이즈는 연락시 말씀주세요)
        (또는 담당강사님께 상담 가능)
  
        ◆ 수령방법 : 배송
        주문 제작 및 해외배송 이기에 재고 있으면 당일 배송, 
        재고가 없으면 4주정도 소요됩니다.`,
      },
      {
        id: 4,
        image: '/images/글라이드.jpg',
        title: '트루다이브 고탄성 수트',
        seller: '딥스프리다이빙',
        date: '2024.02.29',
        description: `◆ 브랜드 : 트루다이브 (TRUDIVE)
  
        ◆ 용도 : 슈트
  
        ◆ 특징 : 탄성이 좋아서 다른 재질보다 입고 벗기가 편한 소재의 슈트입니다. (야마모토)
        후드가 있는 프리다이빙 전용 슈트이며, 글라이드 스킨 재질이라 물 유입이 적고, 저항이 없습니다.
        제주도 투어 또는 울릉도 투어 등 국내투어 가실 땐 슈트가 필수이며, 미리 구매하시는 걸 추천드립니다.
  
        ◆ 색상 : 블랙, 블루, 그린, 레드, 그레이, 퍼플, 실버, 골드
  
        ◆ 재질 : 안감 - 클로즈셀 / 겉감 - 글라이드 스킨
  
        ◆ 가격 : 498,000원 -> 425,000원
  
        * 딥스 프리다이빙 공보경강사님이 엠버서더로 있는 브랜드이기에 저렴하게 구매가능한 브랜드입니다.
  
        ◆ 구매 댓글 예시 : 홍길동 / 010-1234-5678 / 블랙 (사이즈는 연락시 말씀주세요)
        (또는 담당강사님께 상담 가능)
  
        ◆ 수령방법 : 배송
        주문 제작 및 해외배송 이기에 재고 있으면 당일 배송, 
        재고가 없으면 4주정도 소요됩니다.`,
      },
      {
        id: 5,
        image: '/images/글라이드.jpg',
        title: '트루다이브 고탄성 수트',
        seller: '딥스프리다이빙',
        date: '2024.02.29',
        description: `◆ 브랜드 : 트루다이브 (TRUDIVE)
  
        ◆ 용도 : 슈트
  
        ◆ 특징 : 탄성이 좋아서 다른 재질보다 입고 벗기가 편한 소재의 슈트입니다. (야마모토)
        후드가 있는 프리다이빙 전용 슈트이며, 글라이드 스킨 재질이라 물 유입이 적고, 저항이 없습니다.
        제주도 투어 또는 울릉도 투어 등 국내투어 가실 땐 슈트가 필수이며, 미리 구매하시는 걸 추천드립니다.
  
        ◆ 색상 : 블랙, 블루, 그린, 레드, 그레이, 퍼플, 실버, 골드
  
        ◆ 재질 : 안감 - 클로즈셀 / 겉감 - 글라이드 스킨
  
        ◆ 가격 : 498,000원 -> 425,000원
  
        * 딥스 프리다이빙 공보경강사님이 엠버서더로 있는 브랜드이기에 저렴하게 구매가능한 브랜드입니다.
  
        ◆ 구매 댓글 예시 : 홍길동 / 010-1234-5678 / 블랙 (사이즈는 연락시 말씀주세요)
        (또는 담당강사님께 상담 가능)
  
        ◆ 수령방법 : 배송
        주문 제작 및 해외배송 이기에 재고 있으면 당일 배송, 
        재고가 없으면 4주정도 소요됩니다.`,
      },
      {
        id: 6,
        image: '/images/글라이드.jpg',
        title: '트루다이브 고탄성 수트',
        seller: '딥스프리다이빙',
        date: '2024.02.29',
        description: `◆ 브랜드 : 트루다이브 (TRUDIVE)
  
        ◆ 용도 : 슈트
  
        ◆ 특징 : 탄성이 좋아서 다른 재질보다 입고 벗기가 편한 소재의 슈트입니다. (야마모토)
        후드가 있는 프리다이빙 전용 슈트이며, 글라이드 스킨 재질이라 물 유입이 적고, 저항이 없습니다.
        제주도 투어 또는 울릉도 투어 등 국내투어 가실 땐 슈트가 필수이며, 미리 구매하시는 걸 추천드립니다.
  
        ◆ 색상 : 블랙, 블루, 그린, 레드, 그레이, 퍼플, 실버, 골드
  
        ◆ 재질 : 안감 - 클로즈셀 / 겉감 - 글라이드 스킨
  
        ◆ 가격 : 498,000원 -> 425,000원
  
        * 딥스 프리다이빙 공보경강사님이 엠버서더로 있는 브랜드이기에 저렴하게 구매가능한 브랜드입니다.
  
        ◆ 구매 댓글 예시 : 홍길동 / 010-1234-5678 / 블랙 (사이즈는 연락시 말씀주세요)
        (또는 담당강사님께 상담 가능)
  
        ◆ 수령방법 : 배송
        주문 제작 및 해외배송 이기에 재고 있으면 당일 배송, 
        재고가 없으면 4주정도 소요됩니다.`,
      },
      {
        id: 7,
        image: '/images/글라이드.jpg',
        title: '트루다이브 고탄성 수트',
        seller: '딥스프리다이빙',
        date: '2024.02.29',
        description: `◆ 브랜드 : 트루다이브 (TRUDIVE)
  
        ◆ 용도 : 슈트
  
        ◆ 특징 : 탄성이 좋아서 다른 재질보다 입고 벗기가 편한 소재의 슈트입니다. (야마모토)
        후드가 있는 프리다이빙 전용 슈트이며, 글라이드 스킨 재질이라 물 유입이 적고, 저항이 없습니다.
        제주도 투어 또는 울릉도 투어 등 국내투어 가실 땐 슈트가 필수이며, 미리 구매하시는 걸 추천드립니다.
  
        ◆ 색상 : 블랙, 블루, 그린, 레드, 그레이, 퍼플, 실버, 골드
  
        ◆ 재질 : 안감 - 클로즈셀 / 겉감 - 글라이드 스킨
  
        ◆ 가격 : 498,000원 -> 425,000원
  
        * 딥스 프리다이빙 공보경강사님이 엠버서더로 있는 브랜드이기에 저렴하게 구매가능한 브랜드입니다.
  
        ◆ 구매 댓글 예시 : 홍길동 / 010-1234-5678 / 블랙 (사이즈는 연락시 말씀주세요)
        (또는 담당강사님께 상담 가능)
  
        ◆ 수령방법 : 배송
        주문 제작 및 해외배송 이기에 재고 있으면 당일 배송, 
        재고가 없으면 4주정도 소요됩니다.`,
      },
      {
        id: 8,
        image: '/images/글라이드.jpg',
        title: '트루다이브 고탄성 수트',
        seller: '딥스프리다이빙',
        date: '2024.02.29',
        description: `◆ 브랜드 : 트루다이브 (TRUDIVE)
  
        ◆ 용도 : 슈트
  
        ◆ 특징 : 탄성이 좋아서 다른 재질보다 입고 벗기가 편한 소재의 슈트입니다. (야마모토)
        후드가 있는 프리다이빙 전용 슈트이며, 글라이드 스킨 재질이라 물 유입이 적고, 저항이 없습니다.
        제주도 투어 또는 울릉도 투어 등 국내투어 가실 땐 슈트가 필수이며, 미리 구매하시는 걸 추천드립니다.
  
        ◆ 색상 : 블랙, 블루, 그린, 레드, 그레이, 퍼플, 실버, 골드
  
        ◆ 재질 : 안감 - 클로즈셀 / 겉감 - 글라이드 스킨
  
        ◆ 가격 : 498,000원 -> 425,000원
  
        * 딥스 프리다이빙 공보경강사님이 엠버서더로 있는 브랜드이기에 저렴하게 구매가능한 브랜드입니다.
  
        ◆ 구매 댓글 예시 : 홍길동 / 010-1234-5678 / 블랙 (사이즈는 연락시 말씀주세요)
        (또는 담당강사님께 상담 가능)
  
        ◆ 수령방법 : 배송
        주문 제작 및 해외배송 이기에 재고 있으면 당일 배송, 
        재고가 없으면 4주정도 소요됩니다.`,
      }
    // 다른 아이템들을 추가
  ];

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const navigate = useNavigate();

  const handleIconClick = () => {
    navigate('/equipment_purchase_input');
  };

  return (
    <div className="main-container">
      <div className="title-container">
        <h2 className="section-title">장비 구매</h2>
      </div>
      <div className="icon-container">
        <i className="fas fa-edit title-icon" onClick={handleIconClick}></i>
      </div>
      <div className="grid-container">
        {items.map((item) => (
          <div className="card" key={item.id} onClick={() => handleCardClick(item)}>
            <img src={item.image} alt={item.title} className="card-image" />
            <div className="card-content">
              <h3 className="card-title">{item.title}</h3>
              <p className="card-seller">{item.seller}</p>
              <p className="card-date">{item.date}</p>
            </div>
          </div>
        ))}
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="ReactModal__Content"
        overlayClassName="ReactModal__Overlay"
      >
        {selectedItem && (
          <div className="modal-content">
            <div className="modal-header">
              <button className="close-button" onClick={closeModal}>
                <i className="fas fa-times"></i>
              </button>
            </div>
            <img src={selectedItem.image} alt={selectedItem.title} className="modal-image" />
            <div className="modal-description">
              <h2>{selectedItem.title}</h2>
              <p>{selectedItem.date}</p>
              <p>{selectedItem.seller}</p>
              <p className="modal-details">{selectedItem.description}</p>
            </div>
            <div className="modal-footer">
              <button onClick={closeModal} className="modal-button">
                <i className="fa fa-plus-circle"></i> 구매하기
              </button>
              <button onClick={closeModal} className="modal-button">
                <i className="fa fa-comments"></i> 문의
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default EquipmentPurchase;
