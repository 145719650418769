import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, Button, Form, ListGroup, FormCheck } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './common.css';
import './ScheduleDetail.css';
import api from '../utils/api'; // 서버 요청을 위한 api 모듈 임포트

const API_BASE_PATH = window.env.REACT_APP_API_BASE_PATH;

const ScheduleDetail = ({setUserId}) => {
  const location = useLocation();
  const { schedule } = location.state;
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    phone: ''
  });
  const [price, setPrice] = useState('1000'); // 기본 가격 설정
  const [priceOptions, setPriceOptions] = useState([]);
  const [timeConditions, setTimeConditions] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [userLevel, setUserLevel] = useState('');
  const [showFullNames, setShowFullNames] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [priceMessage, setPriceMessage] = useState('');
  const [selectedAdditionalParticipants, setSelectedAdditionalParticipants] = useState([]);
  const [showAdditionalParticipantButton, setShowAdditionalParticipantButton] = useState(false);
  const [showAdditionalParticipantModal, setShowAdditionalParticipantModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedParticipantCount, setSelectedParticipantCount] = useState(1);
  const [displayAdditionalParticipants, setDisplayAdditionalParticipants] = useState([]);
  const [isFreePractice, setIsFreePractice] = useState(false);
  const [isPracticeWaits, setIsPracticeWaits] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(true);

  useEffect(() => {
    setUserLevel(localStorage.getItem("level") || '');
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(storedUserId);
      fetchUserInfo(storedUserId);
    }
    fetchPriceInfo(schedule.id);
    fetchPaymentInfo(schedule.id);
    //checkNameVisibility();
  }, [schedule.id]);

  const fetchUserInfo = async (userId) => {
    try {
      const response = await api.get(`deeps_user/api/v1/user-info/${userId}`);
      setUserInfo({
        name: response.data.name,
        email: response.data.user_email,
        phone: response.data.hp
      });
    } catch (error) {
      console.error('사용자 정보 가져오기 실패:', error);
    }
  };

  const fetchPriceInfo = async (scheduleId) => {
    try {
      const response = await api.get(`${API_BASE_PATH}/get-sports-schedule/${scheduleId}/`);
      const data = response.data;
      setPrice(data.base_price);
      setPriceOptions(data.price_options);
      setTimeConditions(data.time_conditions);
      if (data.last_session_date) {
        const lastSessionDate = new Date(data.last_session_date);
        const currentDate = new Date();
        const monthsDiff = (currentDate.getFullYear() - lastSessionDate.getFullYear()) * 12 + 
                           (currentDate.getMonth() - lastSessionDate.getMonth());

        if (monthsDiff <= 3) {
          setSelectedPrice(data.base_price.toString());
          setPriceMessage('3개월 이내 가격이 적용됩니다.');
        } else {
          const laterPrice = data.time_conditions[0]?.price || data.base_price;
          setSelectedPrice(laterPrice.toString());
          setPriceMessage('3개월 이후 가격이 적용됩니다.');
        }
      } else {
        setSelectedPrice(data.base_price.toString());
        setPriceMessage('');
      }

    } catch (error) {
      console.error('가격 정보 가져오기 실패:', error);
    }
  };
  const fetchPaymentInfo = async (scheduleId) => {
    try {
      const response = await api.get(`${API_BASE_PATH}/get-payment-info/${scheduleId}/`);
      const data = response.data;
      setPaymentStatus(data);
    } catch (error) {
      console.error('결제 정보 가져오기 실패:', error);
    }
  };

  const handlePriceChange = (e) => {
    if (schedule.apply_participants.length >= schedule.total_participants) {
      alert("대기자 신청은 1명 이외에 선택할 수 없습니다.");
      return;
    }

    setSelectedPrice(e.target.value);
    const selectedOption = priceOptions.find(option => option.price.toString() === e.target.value);
    if (selectedOption) {
      setSelectedParticipantCount(selectedOption.participants);
      if (selectedOption.participants > 1) {
        setShowAdditionalParticipantButton(true);
      } else {
        setShowAdditionalParticipantButton(false);
        setSelectedAdditionalParticipants([]);
      }
    } else {
      setSelectedParticipantCount(1);
      setShowAdditionalParticipantButton(false);
      setSelectedAdditionalParticipants([]);
    }
  };

  const openAdditionalParticipantModal = () => {
    // 모달 열기 로직
    setShowAdditionalParticipantModal(true);
  };

  const handleAdditionalParticipantSelect = (user) => {
    if (!selectedAdditionalParticipants.some(id => id === user.id)) {
      setSelectedAdditionalParticipants([...selectedAdditionalParticipants, user.id]);
    }
  };

  const handleAdditionalParticipantRemove = (userId) => {
    setSelectedAdditionalParticipants(selectedAdditionalParticipants.filter(id => id !== userId));
  };

  const handleApply = async () => {
    let totalPrice = selectedPrice;
    let participants = 1 + selectedAdditionalParticipants.length;
    let selectedPriceOption = null;
    let selectedTimeCondition = null;

    if (priceOptions.length > 0) {
      selectedPriceOption = priceOptions.find(option => option.price.toString() === selectedPrice);
      if (selectedPriceOption) {
        if (selectedPriceOption.participants !== participants) {
          alert(`선택한 가격 옵션은 ${selectedPriceOption.participants}명에 해당합니다. 추가 참가자를 정확히 선택해주세요.`);
          return;
        }
        totalPrice = selectedPriceOption.price * participants;
      }
    } else if (timeConditions && timeConditions.length > 0) {
      selectedTimeCondition = timeConditions.find(condition => condition.price.toString() === selectedPrice);
      if (selectedTimeCondition) {
        totalPrice = selectedTimeCondition.price;
      }
    }
    else {
      totalPrice = selectedPrice;
    }

    // 참가자 수 확인
    const totalParticipants = schedule.total_participants;
    const appliedParticipants = schedule.apply_participants.length;
    const availableSpots = totalParticipants - appliedParticipants;

    if (participants > availableSpots) {
      alert(`참가 가능한 인원을 초과했습니다. 최대 ${availableSpots}명까지 신청 가능합니다.`);
      return;
    }

    // 자율연습 참가자 수 확인
    const practiceParticipantsCount = schedule.practice_participants.length;
    const totalPracticeParticipants = schedule.total_practice_participants;
    const availablePracticeSpots = totalPracticeParticipants - practiceParticipantsCount;

    if (isFreePractice && participants > availablePracticeSpots) {
      alert('자율연습 신청이 불가능합니다. 정원이 모두 찼습니다.');
      return;
    }

    let wait_sessions = false;

    const response = await createPaymentInfo(schedule, userInfo, totalPrice, selectedPriceOption, selectedTimeCondition, wait_sessions, selectedAdditionalParticipants, isFreePractice, isPracticeWaits);
    if (response) {
      const paymentInfo = {
        Moid: `course_${schedule.id}_${localStorage.getItem('userId')}_${selectedAdditionalParticipants.join('_')}`,
        GoodsName: `${schedule.sport} - ${schedule.course}`,
        // 일단 테스트로 100원만 결제되도록 설정
        Amt: totalPrice.toString(),
        //Amt: '100',
        ReturnURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_BASE_PATH}/payment-result/`,
        BuyerName: userInfo.name,
        BuyerEmail: userInfo.email,
        BuyerTel: userInfo.phone,
      };

      if (typeof window.initializePayment === 'function') {
        window.initializePayment(paymentInfo);
      } else {
        console.error('initializePayment function is not defined');
        alert('오류가 발생하였습니다. 다시 시도해주세요.');
        return null;
      }
    }
  };

  const handleErrorCode = (code, message) => {
    switch (code) {
      case "1001":
        alert("Authorization header missing");
        break;
      case "1002":
        alert("로그인해주세요.");
        localStorage.removeItem('userId'); // userId 제거
        setUserId(null); // 상태 업데이트
        //navigate('/');
        break;
      case "1003":
        alert("Invalid token");
        break;
      case "1010":
        alert(message);
        break;
      case "1004":
        alert(`Missing required field: ${message}`);
        break;
      case "1005":
        alert("이미 등록되어 있습니다.");
        break;
      case "1006":
        alert(`An error occurred: ${message}`);
        break;
      default:
        alert('결제 준비 중 오류가 발생했습니다. 다시 시도해주세요.');
    }
  };

  const handleApplyWithoutPayment = async () => {
    let totalPrice = selectedPrice;
    let participants = 1;
    let selectedPriceOption = null;
    let selectedTimeCondition = null;

    if (priceOptions.length > 0) {
      selectedPriceOption = priceOptions.find(option => option.price.toString() === selectedPrice);
      if (selectedPriceOption) {
        participants = selectedPriceOption.participants;
        totalPrice = selectedPriceOption.price * participants;
      }
    } else if (timeConditions && timeConditions.length > 0) {
      selectedTimeCondition = timeConditions.find(condition => condition.price.toString() === selectedPrice);
      if (selectedTimeCondition) {
        totalPrice = selectedTimeCondition.price;
      }
    }
    else {
      totalPrice = selectedPrice;
    }
    let wait_sessions = true;

    const response = await createPaymentInfo(schedule, userInfo, totalPrice, selectedPriceOption, selectedTimeCondition, wait_sessions);
    if (response) {
      alert('신청이 성공적으로 완료되었습니다.');
      navigate('/');
    }
  };

  const createPaymentInfo = async (schedule, userInfo, totalPrice, selectedPriceOption, selectedTimeCondition, wait_sessions, additionalParticipants, isFreePractice, isPracticeWaits) => {
    try {
      const response = await api.post(`${API_BASE_PATH}/pre-payment-check-course/`, {
        sport: schedule.sport,
        course: schedule.course,
        selected_price: totalPrice,
        sports_course_id: schedule.id,
        wait_sessions: wait_sessions,
        is_free_practice: isFreePractice,
        is_practice_waits: isPracticeWaits,
        price_option: selectedPriceOption ? {
          participants: selectedPriceOption.participants,
          price: selectedPriceOption.price
        } : null,
        time_condition: selectedTimeCondition ? {
          months_after_previous_course: selectedTimeCondition.months_after_previous_course,
          price: selectedTimeCondition.price
        } : null,
        additional_participants: additionalParticipants, // 추가 참가자 ID 배열
      });

      if (response.data.success) {
        if (response.data.code == '1002' || (response.data.code == '1005')) {
          handleErrorCode(response.data.code, response.data.message);
          return null;
        }
        else if (response.data.code == '1003' || (response.data.code == '1010')) {
          handleErrorCode(response.data.code, response.data.message);
          return null;
        }
        return response;
      } else {
        console.error('서버 응답 실패:', response.data.message);
        handleErrorCode(response.data.code, response.data.message);
        return null;
      }
    } catch (error) {
      console.error('서버 요청 중 오류 발생:', error);
      alert('서버 통신 중 오류가 발생했습니다. 다시 시도해주세요.');
      return null;
    }
  };

  const handleParticipantClick = (participant) => {
    setSelectedParticipant(participant);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedParticipant(null);
  };

  const canViewParticipantInfo = () => {
    return userLevel === '11' || userLevel === '22';
  };

  const checkNameVisibility = async () => {
    try {
      const response = await api.get(`${API_BASE_PATH}/check-name-visibility/${schedule.id}/`);
      setShowFullNames(response.data.show_full_names);
    } catch (error) {
      console.error('이름 표시 여부 확인 실패:', error);
      setShowFullNames(false);
    }
  };

  const apply_other_session = async () => {
    try {
      const response = await api.post(`${API_BASE_PATH}/apply-session/${schedule.id}/`, {
        is_free_practice: isFreePractice,
        is_practice_waits: isPracticeWaits
      });
      alert(response.data.message);
      // 응답 코드에 따른 추가 처리가 필요한 경우 여기에 작성할 수 있습니다.
      // 예를 들어, 성공적인 응답(code: "0000")의 경우 페이지를 새로고침하거나 다른 동작을 수행할 수 있습니다.
      if (response.data.code === "0000") {
        // 성공적인 경우의 추가 동작 (선택적)
        // window.location.reload(); // 페이지 새로고침
      }
    } catch (error) {
      console.error('수강 신청 실패:', error);
      if (error.response && error.response.data) {
        alert(error.response.data.message);
      } else {
        alert('수강 신청 중 오류가 발생했습니다.');
      }
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    
    // 현재 사용자의 ID와 검색어 비교
    const currentUserId = localStorage.getItem('userId');
    if (searchTerm === currentUserId) {
      alert('현재 사용자는 추가 인원으로 등록할 수 없습니다.');
      return;
    }

    try {
      const response = await api.get(`${API_BASE_PATH}/search-users/?id=${searchTerm}`);
      if (response.data.length === 1) {
        const newParticipant = response.data[0].user_id;
        if (selectedAdditionalParticipants.length < selectedParticipantCount - 1) {
          if (!selectedAdditionalParticipants.includes(newParticipant)) {
            setSelectedAdditionalParticipants([...selectedAdditionalParticipants, newParticipant]);
          } else {
            alert('이미 선택된 참가자입니다.');
          }
        } else {
          alert('더 이상 참가자를 추가할 수 없습니다.');
        }
        setSearchTerm('');
      } else {
        alert('사용자를 찾을 수 없습니다.');
      }
    } catch (error) {
      console.error('사용자 검색 중 오류 발생:', error);
      alert('사용자 검색 중 오류가 발생했습니다.');
    }
  };

  const handleConfirmAdditionalParticipants = () => {
    setDisplayAdditionalParticipants([...selectedAdditionalParticipants]);
    setShowAdditionalParticipantModal(false);
  };

  // 알리페이 결제 함수 추가
  const handleAlipay = async () => {
    let totalPrice = selectedPrice;
    let participants = 1 + selectedAdditionalParticipants.length;
    let selectedPriceOption = null;
    let selectedTimeCondition = null;

    if (priceOptions.length > 0) {
      selectedPriceOption = priceOptions.find(option => option.price.toString() === selectedPrice);
      if (selectedPriceOption) {
        if (selectedPriceOption.participants !== participants) {
          alert(`선택한 가격 옵션은 ${selectedPriceOption.participants}명에 해당합니다. 추가 참가자를 정확히 선택해주세요.`);
          return;
        }
        totalPrice = selectedPriceOption.price * participants;
      }
    } else if (timeConditions && timeConditions.length > 0) {
      selectedTimeCondition = timeConditions.find(condition => condition.price.toString() === selectedPrice);
      if (selectedTimeCondition) {
        totalPrice = selectedTimeCondition.price;
      }
    }
    else {
      totalPrice = selectedPrice;
    }

    // 참가자 수 확인
    const totalParticipants = schedule.total_participants;
    const appliedParticipants = schedule.apply_participants.length;
    const availableSpots = totalParticipants - appliedParticipants;

    if (participants > availableSpots) {
      alert(`참가 가능한 인원을 초과했습니다. 최대 ${availableSpots}명까지 신청 가능합니다.`);
      return;
    }

    // 자율연습 참가자 수 확인
    const practiceParticipantsCount = schedule.practice_participants.length;
    const totalPracticeParticipants = schedule.total_practice_participants;
    const availablePracticeSpots = totalPracticeParticipants - practiceParticipantsCount;

    if (isFreePractice && participants > availablePracticeSpots) {
      alert('자율연습 신청이 불가능합니다. 정원이 모두 찼습니다.');
      return;
    }

    let wait_sessions = false;

    const response = await createPaymentInfo(schedule, userInfo, totalPrice, selectedPriceOption, selectedTimeCondition, wait_sessions, selectedAdditionalParticipants, isFreePractice, isPracticeWaits);
    if (response) {
      const alipayPaymentInfo = {
        Moid: `alipay_${schedule.id}_${localStorage.getItem('userId')}_${selectedAdditionalParticipants.join('_')}`,
        GoodsName: `${schedule.sport} - ${schedule.course}`,
        Amt: totalPrice.toString(),
        //Amt: '100', // 테스트용 금액 설정
        ReturnURL: `${window.env.REACT_APP_API_URL}${window.env.REACT_APP_API_BASE_PATH}/payment-result-ali/`,
        RetryURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_BASE_PATH}/payment-retry-ali/`,
        BuyerName: userInfo.name,
        BuyerEmail: userInfo.email,
        BuyerTel: userInfo.phone,
      };

      if (typeof window.initializeAlipay === 'function') {
        window.initializeAlipay(alipayPaymentInfo);
      } else {
        console.error('initializeAlipay 함수가 정의되지 않았습니다.');
        alert('오류가 발생하였습니다. 다시 시도해주세요.');
        return null;
      }
    }
  };

  return (
    <div className="detail-container">
      <img src={`${process.env.PUBLIC_URL}/images/freediving_introduce.png`} alt="스케줄" className="detail-image" />
      <div className="detail-content">
        <h2>
          {schedule.sport} - {schedule.course}
          {schedule.day >= 1 && ` (Days${schedule.day})`}
          {schedule.is_theory && " (이론)"}
        </h2>
        <p className="detail-subcontent">날짜: {schedule.date}</p>
        <p className="detail-subcontent">장소: {schedule.location}</p>
        <p className="detail-subcontent">강사: {schedule.instructor.name}</p>
        <p className="detail-subcontent">시간: {schedule.time}</p>
        <p className="detail-subcontent">
          참가자: {schedule.apply_participants && schedule.apply_participants.length > 0 ? 
            schedule.apply_participants.map((participant, index) => (
              <React.Fragment key={participant.id}>
                {canViewParticipantInfo() ? (
                  <span 
                    onClick={() => handleParticipantClick(participant)}
                    style={{cursor: 'pointer', textDecoration: 'underline'}}
                  >
                    {participant.name}
                  </span>
                ) : (
                  <span>{`${participant.name.charAt(0)}OO`}</span>
                )}
                {index < schedule.apply_participants.length - 1 ? ', ' : ''}
              </React.Fragment>
            )) : '없음'}
        </p>
        <p className="detail-subcontent">
          대기자: {schedule.wait_participants && schedule.wait_participants.length > 0 ? 
            schedule.wait_participants.map((participant, index) => (
              <React.Fragment key={participant.id}>
                {canViewParticipantInfo() ? (
                  <span 
                    onClick={() => handleParticipantClick(participant)}
                    style={{cursor: 'pointer', textDecoration: 'underline'}}
                  >
                    {participant.name}
                  </span>
                ) : (
                  <span>{`${participant.name.charAt(0)}OO`}</span>
                )}
                {index < schedule.wait_participants.length - 1 ? ', ' : ''}
              </React.Fragment>
            )) : '없음'}
        </p>
        <p className="detail-subcontent">
          자율연습 참가자: {schedule.practice_participants && schedule.practice_participants.length > 0 ? 
            schedule.practice_participants.map((participant, index) => (
              <React.Fragment key={participant.id}>
                {canViewParticipantInfo() ? (
                  <span 
                    onClick={() => handleParticipantClick(participant)}
                    style={{cursor: 'pointer', textDecoration: 'underline'}}
                  >
                    {participant.name}
                  </span>
                ) : (
                  <span>{`${participant.name.charAt(0)}OO`}</span>
                )}
                {index < schedule.practice_participants.length - 1 ? ', ' : ''}
              </React.Fragment>
            )) : '없음'}
        </p>
        <p className="detail-subcontent">
          자율연습 대기자: {schedule.practice_wait_participants && schedule.practice_wait_participants.length > 0 ? 
            schedule.practice_wait_participants.map((participant, index) => (
              <React.Fragment key={participant.id}>
                {canViewParticipantInfo() ? (
                  <span 
                    onClick={() => handleParticipantClick(participant)}
                    style={{cursor: 'pointer', textDecoration: 'underline'}}
                  >
                    {participant.name}
                  </span>
                ) : (
                  <span>{`${participant.name.charAt(0)}OO`}</span>
                )}
                {index < schedule.practice_wait_participants.length - 1 ? ', ' : ''}
              </React.Fragment>
            )) : '없음'}
        </p>
        <div className="additional-info">
          <div dangerouslySetInnerHTML={{ __html: schedule.detail_content }} />
        </div>
        
        {paymentStatus && paymentStatus.code === '2001' && priceOptions.length > 0 && (
          <div className="price-selection">
            <label htmlFor="price-select" className="price-label">인원 선택</label>
            <select 
              id="price-select" 
              className="price-select" 
              value={selectedPrice} 
              onChange={handlePriceChange}
            >
              <option value={price}>1인 - {price.toLocaleString()}원</option>
              {priceOptions.map((option, index) => (
                <option key={index} value={option.price}>
                  {option.participants}인 - {option.price.toLocaleString()}원
                </option>
              ))}
            </select>
            {showAdditionalParticipantButton && (
              <button onClick={openAdditionalParticipantModal} className="schedule_detail_additional-participant-button">
                <i className="fas fa-user-plus"></i>
                추가 참가자 등록
              </button>
            )}
          </div>
        )}

        {displayAdditionalParticipants.length > 0 && (
          <div className="additional-participants">
            <h5>추가 참가자:</h5>
            <ul>
              {displayAdditionalParticipants.map((userId, index) => (
                <li key={index}>{userId}</li>
              ))}
            </ul>
          </div>
        )}

        {paymentStatus && (
          <div className="payment-status">
            {console.log('paymentStatus.code:', paymentStatus.code)}
            {paymentStatus.code === '2001' && (
              <div>
                <p>{priceMessage}</p>
                
                <FormCheck 
                  type="checkbox"
                  id="free-practice-checkbox"
                  label={schedule.practice_participants.length >= schedule.total_practice_participants ? "자율연습 대기자 신청" : "자율연습 신청"}
                  checked={schedule.practice_participants.length >= schedule.total_practice_participants ? isPracticeWaits : isFreePractice}
                  onChange={(e) => {
                    if (schedule.practice_participants.length >= schedule.total_practice_participants) {
                      setIsPracticeWaits(e.target.checked);
                    } else {
                      setIsFreePractice(e.target.checked);
                    }
                  }}
                  className="mb-3 free-practice-checkbox"
                />
                <div className="d-flex justify-content-between">
                  <button 
                    className={`btn ${schedule.apply_participants.length >= schedule.total_participants ? 'btn-secondary wait-button' : 'btn-primary apply-button'}`} 
                    onClick={schedule.apply_participants.length >= schedule.total_participants ? handleApplyWithoutPayment : handleApply}
                  >
                    {schedule.apply_participants.length >= schedule.total_participants ? '대기자 신청하기' : (selectedPrice ? `${Number(selectedPrice).toLocaleString()}원 결제하기` : '결제하기')}
                  </button>
                  {schedule.apply_participants.length < schedule.total_participants && (
                    <button className="btn btn-secondary alipay-button" onClick={handleAlipay}>
                      <i className="fab fa-alipay mr-2"></i>알리페이 결제
                    </button>
                  )}
                </div>
              </div>
            )}
            {(paymentStatus.code !== '2001' && paymentStatus.code !== '2000' && showErrorPopup) && (
              <div className="alert-popup">
                <p className="alert-popup-message">{paymentStatus.message}</p>
                <button className="alert-popup-button" onClick={() => setShowErrorPopup(false)}>
                  확인
                </button>
              </div>
            )}
            {paymentStatus.code === '2000' && (
              <div>
                <FormCheck 
                  type="checkbox"
                  id="free-practice-checkbox"
                  label={schedule.practice_participants.length >= schedule.total_practice_participants ? "자율연습 대기자 신청" : "자율연습 신청"}
                  checked={schedule.practice_participants.length >= schedule.total_practice_participants ? isPracticeWaits : isFreePractice}
                  onChange={(e) => {
                    if (schedule.practice_participants.length >= schedule.total_practice_participants) {
                      setIsPracticeWaits(e.target.checked);
                    } else {
                      setIsFreePractice(e.target.checked);
                    }
                  }}
                  className="mb-3 free-practice-checkbox"
                />
                <button className="btn btn-success wait-button" onClick={apply_other_session}>
                  수강 신청하기
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header>
          <Modal.Title className="modal-schedule-title">참가자 정보</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedParticipant && (
            <div className="participant-info">
              <table>
                <tbody>
                  <tr>
                    <th>이름</th>
                    <td>{selectedParticipant.name}</td>
                  </tr>
                  <tr>
                    <th>성별</th>
                    <td>{selectedParticipant.gender === 'M' ? '남자' : '여자'}</td>
                  </tr>
                  <tr>
                    <th>이메일</th>
                    <td>{selectedParticipant.user_email}</td>
                  </tr>
                  <tr>
                    <th>전화번호</th>
                    <td>{selectedParticipant.hp}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleCloseModal}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 추가 참가자 모달 */}
      <Modal show={showAdditionalParticipantModal} onHide={() => setShowAdditionalParticipantModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>추가 참가자 등록 ({selectedAdditionalParticipants.length}/{selectedParticipantCount - 1})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSearch}>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="추가할 수강생 아이디 입력"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="submit">검색</Button>
          </Form>
          <h5 className="mt-4">선택된 참가자</h5>
          <ListGroup>
            {selectedAdditionalParticipants.map(userId => (
              <ListGroup.Item key={userId}>
                {userId}
                <Button variant="danger" size="sm" className="float-right" onClick={() => handleAdditionalParticipantRemove(userId)}>
                  제거
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAdditionalParticipantModal(false)}>
            취소
          </Button>
          <Button variant="primary" onClick={handleConfirmAdditionalParticipants}>
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ScheduleDetail;
