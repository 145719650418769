import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import api from '../utils/api';
import './ParticipantDetail.css';

const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;

const ParticipantDetail = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const participantData = location.state?.participantData;
  const selectedCourse = location.state?.selectedCourse;
  const [messageContent, setMessageContent] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [pastCourses, setPastCourses] = useState([]);
  const [updatedCourseInfo, setUpdatedCourseInfo] = useState(null);

  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['clean']
      ]
    },
    formats: ['bold', 'italic', 'underline', 'strike', 'list', 'bullet'],
    theme: 'snow'
  });

  useEffect(() => {
    if (participantData && participantData.user_id) {
      fetchPastCourses(participantData.user_id);
    }
  }, [participantData]);

  useEffect(() => {
    if (pastCourses.length > 0 && selectedCourse) {
      const matchingCourse = pastCourses.find(course => course.course_id === selectedCourse.id);
      if (matchingCourse) {
        setUpdatedCourseInfo(matchingCourse);
        if (quill && matchingCourse.session_notes.length > 0) {
          const lastNote = matchingCourse.session_notes[matchingCourse.session_notes.length - 1];
          quill.root.innerHTML = lastNote.instructor_note;
        }
      }
    }
  }, [pastCourses, selectedCourse, quill]);

  const fetchPastCourses = async (participantId) => {
    try {
      const response = await api.get(`${API_BASE_PATH}/past-courses/${participantId}`);
      setPastCourses(response.data);
    } catch (error) {
      console.error('과거 수강 내역 가져오기 실패:', error);
      setMessageContent('과거 수강 내역을 가져오는 데 실패했습니다.');
      setShowMessage(true);
    }
  };

  const handleSave = async () => {
    if (quill) {
      const content = quill.root.innerHTML;
      try {
        let session_type = null;
        if (selectedCourse.day == null && selectedCourse.is_theory == true) {
          session_type = "theory";
        } else {
          session_type = `Day${selectedCourse.day}`;
        }
        await api.post(`${API_BASE_PATH}/complete-session/`, {
          sportsCourseId: selectedCourse.id,
          participantId: participantData.user_id,
          session_number: session_type,
          instructor_note: content
        });
        setMessageContent('수강생 기록이 성공적으로 저장되었습니다.');
        setShowMessage(true);
      } catch (error) {
        console.error('수강생 기록 저장 중 오류 발생:', error);
        setMessageContent('수강생 기록 저장 중 오류가 발생했습니다.');
        setShowMessage(true);
      }
    }
  };

  if (!participantData || !selectedCourse) {
    return <div>데이터를 불러올 수 없습니다.</div>;
  }

  return (
    <div className="participant-detail-container">
      <h2>참가자 상세 정보</h2>
      <div className="participant-info">
        <p><strong>이름:</strong> {participantData.name}</p>
        <p><strong>이메일:</strong> {participantData.email}</p>
        <p><strong>전화번호:</strong> {participantData.phone}</p>
      </div>
      <div className="course-info">
        <h3>코스 정보</h3>
        <p><strong>스포츠:</strong> {updatedCourseInfo ? updatedCourseInfo.sport : selectedCourse.sport}</p>
        <p><strong>코스:</strong> {updatedCourseInfo ? updatedCourseInfo.course_type : selectedCourse.course}</p>
        <p><strong>장소:</strong> {updatedCourseInfo ? updatedCourseInfo.location : selectedCourse.location}</p>
        <p><strong>날짜:</strong> {updatedCourseInfo ? updatedCourseInfo.date : selectedCourse.date}</p>
        <p><strong>시간:</strong> {updatedCourseInfo ? `${updatedCourseInfo.start_time} - ${updatedCourseInfo.end_time}` : `${selectedCourse.startTime} - ${selectedCourse.endTime}`}</p>
        <p><strong>강사:</strong> {updatedCourseInfo ? updatedCourseInfo.instructor.name : selectedCourse.instructor?.name}</p>
        <p><strong>총 세션:</strong> {updatedCourseInfo ? updatedCourseInfo.total_sessions : selectedCourse.totalSessions}</p>
        <p><strong>완료된 세션:</strong> {updatedCourseInfo ? updatedCourseInfo.completed_sessions : selectedCourse.completedSessions}</p>
      </div>
      <div className="participant-record">
        <h3>수강생 기록</h3>
        <div ref={quillRef} />
      </div>
      <button onClick={handleSave} className="save-button">저장</button>
      <button onClick={() => navigate(-1)} className="back-button">뒤로 가기</button>
      {showMessage && (
        <div className="message-box">
          <p>{messageContent}</p>
          <button onClick={() => setShowMessage(false)}>닫기</button>
        </div>
      )}
    </div>
  );
};

export default ParticipantDetail;
