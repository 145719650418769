import React, { useState, useEffect, useRef } from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './common.css';
import './SportsInput.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import api from '../utils/api';
import { useLocation, useNavigate } from 'react-router-dom';

const SportsInput = () => {
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean']
      ],
      clipboard: {
        matchVisual: false,
      }
    },
    formats: ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'link', 'image'],
    theme: 'snow',
  });

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [isTimePickerOpen, setTimePickerOpen] = useState({ start: false, end: false });
  const [sports, setSports] = useState([]);
  const [baseCourses, setBaseCourses] = useState([]);
  const [sportsCourses, setSportsCourses] = useState({});
  const [showTheoryOption, setShowTheoryOption] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedSport, setSelectedSport] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [isTheory, setIsTheory] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [maxLessonParticipants, setMaxLessonParticipants] = useState(1);
  const [maxPracticeParticipants, setMaxPracticeParticipants] = useState(1);
  const [showMessage, setShowMessage] = useState(false);
  const [courseLevel, setCourseLevel] = useState(0);
  const [messageContent, setMessageContent] = useState('');
  const navigate = useNavigate();

  const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const courseId = searchParams.get('courseId');

  const [userLevel, setUserLevel] = useState('');
  const [selectedDays, setSelectedDays] = useState(1);
  const [hasTheory, setHasTheory] = useState(false);
  const [maxDays, setMaxDays] = useState(0);

  const startTimePickerRef = useRef(null);
  const endTimePickerRef = useRef(null);
  const datePickerRef = useRef(null);

  useEffect(() => {
    const fetchBaseCourses = async () => {
      try {
        const response = await api.get(`${API_BASE_PATH}/base-sports-courses/`);
        const data = response.data;

        const coursesMap = {};
        const uniqueSports = [];

        for (let i = 0; i < data.base_courses.length; i++) {
          const item = data.base_courses[i];
          const sportName = item.sport.name;
          const courseType = item.course_type;

          if (!uniqueSports.includes(sportName)) {
            uniqueSports.push(sportName);
          }

          if (!coursesMap[sportName]) {
            coursesMap[sportName] = [];
          }

          if (!coursesMap[sportName].some(course => course.id === courseType.id)) {
            coursesMap[sportName].push({
              id: courseType.id,
              name: courseType.name
            });
          }
        }

        setSports(uniqueSports);
        setSportsCourses(coursesMap);
        setBaseCourses(data.base_courses);
        setLocations(data.locations.map(loc => loc.name));
        setSelectedSport(uniqueSports[0] || '');
        setSelectedLocation(data.locations[0]?.name || '');
        setSelectedCourse(coursesMap[uniqueSports[0]]?.[0].name || '');

        return true; // 기본 데이터 설정 완료를 나타냄
      } catch (error) {
        console.error('기본 데이터 가져오기 실패:', error);
        return false;
      }
    };

    const fetchCourseData = async () => {
      if (courseId && quill) {
        try {
          const response = await api.get(`${API_BASE_PATH}/get-sports-schedule/${courseId}/`);
          const courseData = response.data;
          
          setSelectedSport(courseData.sport);
          setSelectedLocation(courseData.location);
          setSelectedCourse(courseData.course);
          setSelectedDate(new Date(courseData.date));
          setStartTime(new Date(`2000-01-01T${courseData.startTime}`));
          setEndTime(new Date(`2000-01-01T${courseData.endTime}`));
          setMaxLessonParticipants(courseData.maxLessonParticipants);
          setMaxPracticeParticipants(courseData.maxPracticeParticipants);
          
          setMaxDays(courseData.max_days);
          setShowTheoryOption(courseData.has_theory);
          setHasTheory(courseData.has_theory);
          setSelectedDays(courseData.day);
          if (courseData.has_theory) {
            setIsTheory(courseData.is_theory);
          }
          setCourseLevel(courseData.allowed_user_levels);

          quill.root.innerHTML = courseData.content;
        } catch (error) {
          console.error('코스 데이터 가져오기 실패:', error);
        }
      }
    };

    const initializeData = async () => {
      const baseDataLoaded = await fetchBaseCourses();
      if (baseDataLoaded) {
        await fetchCourseData();
      }
    };

    initializeData();
  }, [courseId, quill]);

  useEffect(() => {
    // 사용자 레벨을 가져오는 로직
    const level = localStorage.getItem("level");
    setUserLevel(level);
  }, []);

  const handleSportChange = (e) => {
    setSelectedSport(e.target.value);
  };

  const handleCourseChange = (e) => {
    const selectedCourseName = e.target.value;
    setSelectedCourse(selectedCourseName);

    const selectedCourseData = baseCourses.find(course => 
      course.sport.name === selectedSport && course.course_type.name === selectedCourseName
    );

    if (selectedCourseData) {
      setMaxDays(selectedCourseData.max_days);
      setShowTheoryOption(selectedCourseData.has_theory);
      setHasTheory(selectedCourseData.has_theory);
      if (selectedCourseData.max_days > 1) {
        setSelectedDays(selectedCourseData.day); // 기본값으로 1일 설정
      }
    }
  };

  // 사용자 정의 이미지 업로드 처리
  useEffect(() => {
    if (quill) {
      quill.getModule('toolbar').addHandler('image', () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
          const file = input.files[0];
          const formData = new FormData();
          formData.append('file', file);

          try {
            const response = await api.post(`${API_BASE_PATH}/upload-image/`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            const url = response.data.image_url;
            const range = quill.getSelection();
            quill.insertEmbed(range.index, 'image', url);
            quill.setSelection(range.index + 1);
          } catch (error) {
            console.error('Image upload failed:', error);
          }
        };
      });
    }
  }, [quill]);

  const handleSave = async () => {
    if (quill) {
      const content = quill.root.innerHTML;
      
      // 날짜를 YYYY-MM-DD 형식으로 변환
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      // 시간을 HH:MM 형식으로 변환
      const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
      };

      let lastSelectedDays = 0;
      if (maxDays > 1) {
        if (selectedDays == undefined) {
          lastSelectedDays = 1;
        }
        else {
          lastSelectedDays = selectedDays;
        }
      }
      else {
        lastSelectedDays = 0;
      }

      const data = {
        sport: selectedSport,
        location: selectedLocation,
        course: selectedCourse,
        date: formatDate(selectedDate),
        startTime: formatTime(startTime),
        endTime: formatTime(endTime),
        maxLessonParticipants,
        maxPracticeParticipants,
        content,
        day: lastSelectedDays, // 일차를 0부터 시작하도록 변경
        is_theory: isTheory, // 이론 수업 여부 추가
        allowed_user_levels: courseLevel, // 수강 레벨 추가
      };

      try {
        let response;
        if (courseId) {
          // 기존 코스 수정
          response = await api.put(`${API_BASE_PATH}/update-sports-schedule/${courseId}/`, data);
          setMessageContent('코스가 성공적으로 수정되었습니다.');
        } else {
          // 새 코스 생성
          response = await api.post(`${API_BASE_PATH}/save-sports-schedule/`, data);
          setMessageContent('새로운 코스가 성공적으로 생성되었습니다.');
        }
        setShowMessage(true);
      } catch (error) {
        console.error('코스 저장 중 오류 발생:', error);
        setMessageContent('코스 저장 중 오류가 발생했습니다.');
        setShowMessage(true);
      }
    }
  };

  const handleApprove = async () => {
    if (quill) {
      const content = quill.root.innerHTML;
      
      // 날짜를 YYYY-MM-DD 형식으로 변환
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      // 시간을 HH:MM 형식으로 변환
      const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
      };

      const data = {
        sport: selectedSport,
        location: selectedLocation,
        course: selectedCourse,
        date: formatDate(selectedDate),
        startTime: formatTime(startTime),
        endTime: formatTime(endTime),
        maxLessonParticipants,
        maxPracticeParticipants,
        content,
        allowed_user_levels: courseLevel, // 수강 레벨 추가
        status: 'approved'  // 승인 상태 추가
      };

      try {
        let response;
        if (courseId) {
          // 기존 코스 승인
          response = await api.put(`${API_BASE_PATH}/approve-sports-schedule/${courseId}/`, data);
          setMessageContent('성공적으로 승인되었습니다.');
        } 
        setShowMessage(true);
      } catch (error) {
        console.error('코스 승인 중 오류 발생:', error);
        setMessageContent('코스 승인 중 오류가 발생했습니다.');
        setShowMessage(true);
      }
    }
  };

  const handleMessageClick = () => {
    setShowMessage(false);
    navigate('/sports-learning');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (startTimePickerRef.current && !startTimePickerRef.current.contains(event.target)) {
        setTimePickerOpen(prev => ({ ...prev, start: false }));
      }
      if (endTimePickerRef.current && !endTimePickerRef.current.contains(event.target)) {
        setTimePickerOpen(prev => ({ ...prev, end: false }));
      }
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setDatePickerOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="input-container">
      <h2 className="input-section-title">스포츠 학습 입력</h2>

      <div className="input-form-group">
        <div className="input-row">
          <div className="input-item">
            <label>스포츠<br/>종목</label>
            <select 
              className="sportsinput-form-control"
              value={selectedSport}
              onChange={handleSportChange}
            >
              {Object.keys(sportsCourses).map((sportName, index) => (
                <option key={index} value={sportName}>{sportName}</option>
              ))}
            </select>
          </div>
          <div className="input-item">
            <label>과정</label>
            <select 
              className="sportsinput-form-control"
              value={selectedCourse}
              onChange={handleCourseChange}
            >
              {sportsCourses[selectedSport]?.map((course, index) => (
                <option key={index} value={course.name}>{course.name}</option>
              ))}
            </select>
          </div>
        </div>
        {maxDays >= 2 && (
        <div className="input-row">
          <div className="input-item">
            <label>일차</label>
            <select
              className="sportsinput-form-control"
              value={selectedDays}
              onChange={(e) => setSelectedDays(Number(e.target.value))}
            >
              {[...Array(maxDays)].map((_, i) => (
                <option key={i} value={i + 1}>Days {i + 1}</option>
              ))}
            </select>
          </div>
          {showTheoryOption && (
          <div className="sportsinput-toggle-item">
            <label className="sportsinput-toggle-label">이론수업</label>
            <div className="sportsinput-toggle-container">
              <label className="sportsinput-toggle-switch">
                <input
                  type="checkbox"
                  checked={isTheory}
                  onChange={(e) => setIsTheory(e.target.checked)}
                />
                <span className="sportsinput-toggle-slider"></span>
              </label>
            </div>
          </div>
          )}
        </div>
        )}
        <div className="input-row">
        <div className="input-item">
            <label>장소</label>
            <select 
              className="sportsinput-form-control"
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
            >
              {locations.map((location, index) => (
                <option key={index} value={location}>{location}</option>
              ))}
            </select>
          </div>
          <div className="input-item">
            <label>일정</label>
            <div className="sportsinput-form-control date-picker" onClick={() => setDatePickerOpen(!isDatePickerOpen)}>
              {selectedDate ? selectedDate.toLocaleDateString() : "날짜를 선택하세요"}
            </div>
            {isDatePickerOpen && (
              <div className="sportsinput-custom-modal" ref={datePickerRef}>
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date);
                    setDatePickerOpen(false);
                  }}
                  inline
                />
              </div>
            )}
          </div>
        </div>

        <div className="input-row">
          <div className="input-item">
            <label>시작 시간</label>
            <div className="sportsinput-form-control date-picker" onClick={() => setTimePickerOpen({ ...isTimePickerOpen, start: !isTimePickerOpen.start })}>
              {startTime ? startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : "시작 시간을 선택하세요"}
            </div>
            {isTimePickerOpen.start && (
              <div className="sportsinput-custom-modal" ref={startTimePickerRef}>
                <DatePicker
                  selected={startTime}
                  onChange={(date) => {
                    setStartTime(date);
                    setTimePickerOpen({ ...isTimePickerOpen, start: false });
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="시간"
                  dateFormat="hh:mm"
                  inline
                />
              </div>
            )}
          </div>
          <div className="input-item">
            <label>종료 시간</label>
            <div className="sportsinput-form-control date-picker" onClick={() => setTimePickerOpen({ ...isTimePickerOpen, end: !isTimePickerOpen.end })}>
              {endTime ? endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : "종료 시간을 선택하세요"}
            </div>
            {isTimePickerOpen.end && (
              <div className="sportsinput-custom-modal" ref={endTimePickerRef}>
                <DatePicker
                  selected={endTime}
                  onChange={(date) => {
                    setEndTime(date);
                    setTimePickerOpen({ ...isTimePickerOpen, end: false });
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="시간"
                  dateFormat="hh:mm"
                  inline
                />
              </div>
            )}
          </div>
        </div>

        <div className="input-row">
          <div className="input-item">
            <label>강습<br/>최대인원</label>
            <select 
              className="sportsinput-form-control"
              value={maxLessonParticipants}
              onChange={(e) => setMaxLessonParticipants(Number(e.target.value))}
            >
              {[1,2,3,4,5,6,7,8,9,10].map((num) => (
                <option key={num} value={num}>{num}</option>
              ))}
            </select>
          </div>
          <div className="input-item">
            <label>연습반<br/>최대인원</label>
            <select 
              className="sportsinput-form-control"
              value={maxPracticeParticipants}
              onChange={(e) => setMaxPracticeParticipants(Number(e.target.value))}
            >
              {[1,2,3,4,5,6,7,8,9,10].map((num) => (
                <option key={num} value={num}>{num}</option>
              ))}
            </select>
          </div>
        </div>
        
        <div className="input-row">
          <div className="input-item">
            <label>수강신청 레벨</label>
            <select 
              className="sportsinput-form-control"
              value={courseLevel}
              onChange={(e) => setCourseLevel(Number(e.target.value))}
            >
              {[0,1,2,3,4,5,6,7,8,9].map((level) => (
                <option key={level} value={level}>{level}</option>
              ))}
            </select>
          </div>
        </div>


        <div className="sportsinput-form-group-detailed">
          <label>상세 내용</label>
          <div>
            <div ref={quillRef} />
          </div>
        </div>

        <div className="sportsinput-div-input-save">
          {userLevel === "22" ? (
            <button className="sportsinput-btn btn-primary save-button" onClick={handleApprove}>승인</button>
          ) : (
            <button className="sportsinput-btn btn-primary save-button" onClick={handleSave}>저장</button>
          )}
        </div>
      </div>

      {showMessage && (
        <div className="message-box">
          <p>{messageContent}</p>
          <button className="messagebox-close-button" onClick={() => setShowMessage(false)}>
            닫기
          </button>
      </div>
      )}
    </div>
  );
};

export default SportsInput;