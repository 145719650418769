import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './common.css';
import Calendar from 'react-calendar';
import TravelCard from '../components/TravelCard';
import 'react-calendar/dist/Calendar.css';
import api from '../utils/api';

const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;

const TravelProducts = () => {
  const [travelProducts, setTravelProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [clickedDate, setClickedDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTravel, setSelectedTravel] = useState(null);
  const [locations, setLocations] = useState('전체');
  const [guides, setGuides] = useState('전체');
  const [selectedProduct, setSelectedProduct] = useState('전체');
  const [level, setLevel] = useState(0);

  useEffect(() => {
    const fetchTravelProducts = async () => {
      try {
        const response = await api.get(`${API_BASE_PATH}/travel-products/`);
        setTravelProducts(response.data);
        const dates = response.data.map(schedule => {
          const [year, month, day] = schedule.start_date.split('-');
          return new Date(year, month - 1, day);
        });
        setSelectedDates(dates);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    fetchTravelProducts();
  }, []);

  useEffect(() => {
    const userLevel = localStorage.getItem("level");
    setLevel(parseInt(userLevel) || 0);
  }, []);

  const handleTravelProductChange = (productId) => {
    console.log('선택된 상품 ID:', productId); // 디버깅을 위한 로그 추가
    setSelectedProduct(productId);
    if (productId === '전체') {
      setLocations('전체');
      setGuides('전체');
      const dates = travelProducts.map(product => {
        const [year, month, day] = product.start_date.split('-');
        return new Date(year, month - 1, day);
      });
      setSelectedDates(dates);
    } else {
      const selectedProducts = travelProducts.filter(product => product.name === productId);
      if (selectedProducts.length > 0) {
        setLocations(selectedProducts[0].location);
        setGuides(selectedProducts[0].guide);
        const dates = selectedProducts.map(product => {
          const [year, month, day] = product.start_date.split('-');
          return new Date(year, month - 1, day);
        });
        setSelectedDates(dates);
      }
    }
  };

  const handleDateClick = (date) => {
    if (selectedDates.some(selectedDate => selectedDate.getTime() === date.getTime())) {
      setClickedDate(prevDate => {
        const newDate = prevDate && prevDate.getTime() === date.getTime() ? null : date;
        return newDate;
      });
    }
  };

  const handleCardClick = (product) => {
    navigate(`/travel-product-detail/`, { state: { product } });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTravel(null);
  };

  useEffect(() => {
    const fetchTravelProducts = async () => {
      try {
        const response = await api.get(`${API_BASE_PATH}/travel-products/`);
        setTravelProducts(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    fetchTravelProducts();
  }, []);

  

  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      console.log("tileClassName - Processing date:", date);

      const day = date.getDay();

      if (clickedDate && clickedDate.getTime() === date.getTime()) {
        console.log("tileClassName - Applying 'clicked-date' class to:", date);
        return 'clicked-date';
      }

      if (selectedDates.some(selectedDate => selectedDate.getTime() === date.getTime())) {
        console.log("tileClassName - Applying 'custom-selected-date' class to:", date);
        return 'custom-selected-date';
      }

      if (day === 0) {
        console.log("tileClassName - Applying 'sunday' class to:", date);
        return 'sunday';
      } else if (day === 6) {
        console.log("tileClassName - Applying 'saturday' class to:", date);
        return 'saturday';
      }

      console.log("tileClassName - No special class applied for:", date);
    }

    return null;
  };

  const navigate = useNavigate();

  const handleIconClick = () => {
    navigate('/travel_products_input');
  };

  return (
    <div className="main-container">
      <div className="title-container">
        <h2 className="section-title">여행 상품</h2>
      </div>
      {level > 10 && (
        <div className="icon-container">
          <i className="fas fa-edit title-icon" onClick={handleIconClick}></i>
        </div>
      )}

      <div className="filters">
        <div className="filter-row">
          <div className="filter-item">
            <label>상품명</label>
            <select className="custom-input" value={selectedProduct} onChange={(e) => handleTravelProductChange(e.target.value)}>
              <option value="전체">전체</option>
              {travelProducts.map(product => (
                <option key={product.id} value={product.name}>{product.name}</option>
              ))}
            </select>
          </div>
          <div className="filter-item">
            <label>장소</label>
            <input type="text" className="custom-input" value={locations} readOnly />
          </div>
          <div className="filter-item">
            <label>인솔자</label>
            <input type="text" className="custom-input" value={guides} readOnly />
          </div>
        </div>
      </div>
      <div className="calendar-container">
        <Calendar
          value={selectedDates}
          onClickDay={handleDateClick}
          tileClassName={tileClassName}
          className="custom-calendar"
          locale="ko-KR"
        />
      </div>
      <div className="travel-cards-container">
        {loading ? (
          <p>로딩 중...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          travelProducts
            .filter(product => selectedProduct === '전체' || product.name === selectedProduct)
            .map((product) => (
              <TravelCard
                key={product.id}
                title={product.name}
                dateRange={`${product.start_date} ~ ${product.end_date}`}
                guides={[product.guide]}
                onClick={() => handleCardClick(product)}
                maxParticipants={product.max_participants}
                paidCount={product.paid_participants ? product.paid_participants.length : 0}
                unpaidCount={product.unpaid_participants ? product.unpaid_participants.length : 0}
              />
            ))
        )}
      </div>
      {/* <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="ReactModal__Content"
        overlayClassName="ReactModal__Overlay"
      >
        {selectedTravel && (
          <div className="modal-content">
            <img 
              src={selectedTravel.cover_image} 
              alt={selectedTravel.name} 
              className="modal-image" 
            />
            <div className="modal-description">
              <h2>{selectedTravel.name}</h2>
              <p>{selectedTravel.start_date} ~ {selectedTravel.end_date}</p>
              <p>장소: {selectedTravel.location}</p>
              <p>인솔자: {selectedTravel.guide}</p>
              <p>최대 참가자 수: {selectedTravel.max_participants}</p>
              <p>요구사항: {selectedTravel.requirements}</p>
              <div dangerouslySetInnerHTML={{ __html: selectedTravel.detailed_content }} />
            </div>
            <div className="modal-footer">
              <button onClick={closeModal} className="modal-button">
                <i className="fa fa-plus-circle"></i> 신청
              </button>
              <button onClick={closeModal} className="modal-button">
                <i className="fa fa-question-circle"></i> Q&A
              </button>
              <button onClick={closeModal} className="modal-button">
                <i className="fa fa-comments"></i> 문의
              </button>
            </div>
          </div>
        )}
      </Modal> */}
    </div>
  );
};

export default TravelProducts;
