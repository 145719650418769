import React, { useState } from 'react';
import Slider from 'react-slick';
import Modal from 'react-modal';
import { Link, useNavigate } from 'react-router-dom';
import api from '../utils/api';
import Cookies from 'js-cookie';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "./common.css";
import "./MainPage.css";

import SportsLearning from './SportsLearning';  // SportsLearning 컴포넌트를 가져옵니다.

const eventImages = [
  {
    src: `${process.env.PUBLIC_URL}/images/slides/slide_sample1.png`,
    title: '다양한 이벤트 1',
    description: '이벤트 응모하고 상품받자 1!',
    buttonText: '바로가기'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/slides/slide_sample2.png`,
    title: '다양한 이벤트 2',
    description: '이벤트 응모하고 상품받자 2!',
    buttonText: '바로가기'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/slides/slide_sample1.png`,
    title: '다양한 이벤트 3',
    description: '이벤트 응모하고 상품받자 3!',
    buttonText: '바로가기'
  }
];

const sports = [
  {
    src: `${process.env.PUBLIC_URL}/images/freediving.png`,
    title: '프리다이빙',
    description: '프리다이빙은 깊은 물속에서의 자유와 도전을 동시에 경험할 수 있는 매력적인 스포츠입니다. 철저한 훈련과 안전 조치를 통해 물속에서의 아름다움을 만끽할 수 있습니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/scuba-diving.png`,
    title: '스쿠버다이빙',
    description: '스쿠버다이빙은 수중에서의 자유와 아름다움을 경험할 수 있는 스포츠입니다. 전문 장비와 훈련을 통해 깊은 바다 속을 탐험할 수 있습니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/mermaid.png`,
    title: '머메이드',
    description: '머메이드는 인어처럼 수영하며 물속에서의 자유로움을 느낄 수 있는 운동입니다. 수영 실력을 향상시키고 물속에서의 우아함을 배울 수 있습니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/swimming.png`,
    title: '수영',
    description: '수영은 전신 운동으로 체력 향상과 건강 증진에 도움이 됩니다. 다양한 수영 기술을 배우고 즐길 수 있는 대표적인 스포츠입니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/golf.png`,
    title: '골프',
    description: '골프는 전략과 집중력을 요구하는 스포츠입니다. 넓은 필드에서의 여유로움과 함께 스윙 기술을 향상시킬 수 있습니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/cycling.png`,
    title: 'Cycling',
    description: '사이클링은 체력과 지구력을 기르는 운동입니다. 도로에서의 자유로운 주행을 통해 스트레스를 해소할 수 있습니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/running.png`,
    title: '러닝',
    description: '러닝은 간단하지만 효과적인 운동입니다. 체력 향상과 함께 다양한 경로를 달리며 성취감을 느낄 수 있습니다.'
  },
  {
    src: `${process.env.PUBLIC_URL}/images/yoga.png`,
    title: '요가',
    description: '요가는 몸과 마음의 균형을 맞추는 운동입니다. 유연성과 체력 향상, 정신적인 안정감을 얻을 수 있습니다.'
  }
];

const WATER_SPORTS = ['프리다이빙', '스쿠버다이빙', '머메이드'];

Modal.setAppElement('#root');


function MainPage({ isLoginModalOpen, closeLoginModal, userId, setUserId }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSport, setSelectedSport] = useState(null);
  const [password, setPassword] = useState(''); // 비밀번호 state 추가
  const navigate = useNavigate(); // useNavigate 훅 사용

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
  };

  const openModal = (sport) => {
    setSelectedSport(sport);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSport(null);
  };

  const handleSignupClick = (e) => {
    e.preventDefault();
    closeLoginModal();
    navigate('/signup');
  };

  const handleApplyClick = () => {
    navigate('/sports-learning');
  };
  
  return (
    <div className="main-container">
      <div className="event-section">
        <Slider {...settings}>
          {eventImages.map((image, index) => (
            <div key={index} className="slide">
              <div className="slide-block">
                <img src={image.src} alt={image.title} className="event-image" />
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="sports-section">
        <div className="sports-grid">
          {sports.map((sport, index) => (
            <div key={index} className="sport-item" onClick={() => openModal(sport)}>
              <div className="sport-icon">
                <img src={sport.src} alt={sport.title} />
              </div>
              <p>{sport.title}</p>
            </div>
          ))}
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="ReactModal__Content"
        overlayClassName="ReactModal__Overlay"
      >
        {selectedSport && (
          <div className="mainpage-modal-content">
            <button onClick={closeModal} className="modal-close-btn">
              <i className="fas fa-times"></i>
            </button>
            <div className="modal-header">
              <img src={selectedSport.src} alt={selectedSport.title} className="modal-image" />
              <h2>{selectedSport.title}</h2>
            </div>
            <div className="modal-description">
              <p>{selectedSport.description}</p>
            </div>
            <div className="modal-footer">
              {WATER_SPORTS.includes(selectedSport.title) && (
                <button onClick={handleApplyClick} className="modal-button apply-btn">
                    <i className="fas fa-plus-circle"></i> 신청
                </button>
              )}
            </div>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={isLoginModalOpen}
        onRequestClose={closeLoginModal}
        className="ReactModal__Content"
        overlayClassName="ReactModal__Overlay"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h2>Login</h2>
            <button className="close-button" onClick={closeLoginModal}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="modal-body">
            <input
              type="text"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              placeholder="Enter your ID"
              className="modal-input"
            />
            <input
              type="password"
              value={password} // 밀번호 입력 필드에 state 연결
              onChange={(e) => setPassword(e.target.value)} // 밀번호 변경 핸들러 추가
              placeholder="Enter your Password"
              className="modal-input"
            />
            <Link to="/signup" onClick={handleSignupClick} className="signup-link">회원가입</Link>
          </div>
        </div>
      </Modal>

    </div>
  );
}

export default MainPage;
