import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import MainPage from './pages/MainPage';
import SportsLearning from './pages/SportsLearning';
import SportsInput from './pages/SportsInput';
import TravelProducts from './pages/TravelProducts';
import TravelProductsInput from './pages/TravelProductsInput';
import TravelProductsDetail from './pages/TravelProductsDetail';
import EquipmentPurchase from './pages/EquipmentPurchase';
import EquipmentPurchaseInput from './pages/EquipmentPurchaseInput';
import ParticipantDetail from './pages/ParticipantDetail';
import ApplyHistory from './User/ApplyHistory';
import ScheduleDetail from './pages/ScheduleDetail';
import SignupPage from './pages/SignupPage';
import PaymentResult from './pages/PaymentResult';
import UserProfile from './pages/UserProfile';
import LoginModal from './components/LoginModal';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "./App.css"

function App() {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [level, setLevel] = useState('');

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    const storedLevel = localStorage.getItem('level');
    if (storedUserId) {
      setUserId(storedUserId);
    }
    if (storedLevel) {
      setLevel(storedLevel);
    }
  }, []);

  const handleLoginClick = () => {
    setIsLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const updateUserId = (newUserId) => {
    setUserId(newUserId);
    localStorage.setItem('userId', newUserId);
  };

  const updateLevel = (newLevel) => {
    setLevel(newLevel);
    localStorage.setItem('level', newLevel);
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Navigation onLoginClick={handleLoginClick} userId={userId} level={level} />
        </header>
        <div className="App-body">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/sports-learning" element={<SportsLearning />} />
            <Route path="/input" element={<SportsInput />} />
            <Route path="/travel_product" element={<TravelProducts />} />
            <Route path="/travel_products_input" element={<TravelProductsInput setUserId={updateUserId} />} />
            <Route path="/travel-product-detail" element={<TravelProductsDetail setUserId={updateUserId} />} />
            <Route path="/equipment_purchase" element={<EquipmentPurchase />} />
            <Route path="/equipment_purchase_input" element={<EquipmentPurchaseInput />} />
            <Route path="/apply_history" element={<ApplyHistory />} />
            <Route path="/schedule/:id" element={<ScheduleDetail setUserId={updateUserId} />} />
            <Route path="/signup" element={<SignupPage userId={userId} setUserId={updateUserId} />} />
            <Route path="/payment-result" element={<PaymentResult setUserId={updateUserId} />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/participant-detail" element={<ParticipantDetail />} />
          </Routes>
        </div>
        <LoginModal
          isOpen={isLoginModalOpen}
          onClose={closeLoginModal}
          setUserId={updateUserId}
          setLevel={updateLevel}
        />
      </div>
    </Router>
  );
}

export default App;
