import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import Cookies from 'js-cookie';
import api from '../utils/api';
import './SignupPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { format } from 'date-fns';
import ko from 'date-fns/locale/ko';

const SignupPage = ({ userId, setUserId }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    userId: '',
    password: '',
    confirmPassword: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    phoneVerification: '',
    gender: '',
    level: '',
    birthDate: '',
    referrerId: '',
    referrerPhone: '',
    termsAccepted: false,
    ageConfirmed: false,
    privacyAccepted: false,
    marketingConsent: {
      sms: false,
      email: false,
    },
  });

  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [termsContent, setTermsContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [birthDate, setBirthDate] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/agreement/terms.txt`)
      .then(response => response.text())
      .then(data => setTermsContent(data))
      .catch(error => console.error('Error fetching terms:', error));
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      if (name === 'termsAccepted' || name === 'ageConfirmed' || name === 'privacyAccepted') {
        setFormData({ ...formData, [name]: checked });
      } else {
        setFormData({
          ...formData,
          marketingConsent: { ...formData.marketingConsent, [name]: checked },
        });
      }
    } else if (type === 'radio') {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = date ? format(date, 'yyyy-MM-dd') : '';
    setFormData({ ...formData, birthDate: formattedDate });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // 필수 필드 체크
    const requiredFields = [
      { field: 'userId', message: '아이디를 입력해주세요' },
      { field: 'password', message: '비밀번호를 입력해주세요' },
      { field: 'confirmPassword', message: '비밀번호 확인을 입력해주세요' },
      { field: 'name', message: '이름을 입력해주세요' },
      { field: 'email', message: '이메일을 입력해주세요' },
      { field: 'phone', message: '휴대폰 번호를 입력해주세요' },
      { field: 'birthDate', message: '생년월일을 입력해주세요' },
    ];

    for (let i = 0; i < requiredFields.length; i++) {
      const { field, message } = requiredFields[i];
      if (!formData[field]) {
        alert(message);
        return;
      }
    }

    // 필수 약관 체크
    if (!formData.termsAccepted) {
      alert('이용약관에 동의해주세요');
      return;
    }
    if (!formData.ageConfirmed) {
      alert('본인은 만 14세 이상임을 확인해주세요');
      return;
    }
    if (!formData.privacyAccepted) {
      alert('개인정보 수집 및 이용에 동의해주세요');
      return;
    }
    if (formData.level == '') {
      formData.level = '0';
    }

    if (formData.gender == '') {
      formData.gender = 'M'
    }

    if (!isPhoneVerified) {
      alert('휴대폰 인증을 완료해주세요.');
      return;
    }

    api.post('/deeps_user/register/', {
      user_id: formData.userId,
      password: formData.password,
      name: formData.name,
      user_email: formData.email,
      hp: formData.phone,
      auth: 1,
      user_gender: formData.gender,
      user_birthday: formData.birthDate,
      level: formData.level,
      terms_accepted: 1,
      age_confirmed: 1,
      privacy_accepted: 1,
    })
    .then(response => {
      if (response.data.code === '0000') {
        console.log("회원가입 성공:", response.data);
        const accessToken = response.data.token.access;
        const refreshToken = response.data.token.refresh;
        const level = response.data.user.level;
        localStorage.setItem('accessToken', accessToken);
        Cookies.set('refreshToken', refreshToken, { secure: true, sameSite: 'Strict' });
        localStorage.setItem('userId', formData.userId);
        localStorage.setItem("level", level);
        setUserId(formData.userId);
        setIsSuccessModalOpen(true);  // 성공 모달 열기
      } else {
        console.log("회원가입 실패:", response.data.message);
      }
    })
    .catch(error => {
      console.error("회원가입 중 오류 발생:", error);
    });
  };

  const openTermsModal = (val) => {
    let fileName = '';
    let modalTitle = '';
    if (val === 1) {
      fileName = 'terms.txt';
      modalTitle = '이용약관';
    } else if (val === 2) {
      fileName = 'privacy.txt';
      modalTitle = '개인정보 수집및 이용동의';
    }

    fetch(`${process.env.PUBLIC_URL}/agreement/${fileName}`)
      .then(response => response.text())
      .then(data => setTermsContent(data))
      .catch(error => console.error('Error fetching terms:', error));

    setModalTitle(modalTitle);
    setIsTermsModalOpen(true);
  };

  const closeTermsModal = () => {
    setIsTermsModalOpen(false);
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    navigate('/');  // 메인 페이지로 이동
  };

  const handleSendVerification = async () => {
    if (!formData.phone) {
      alert('휴대폰 번호를 입력해주세요.');
      return;
    }

    try {
      const response = await api.post('/deeps_user/api/v1/auth-message/', {
        hp: formData.phone
      });

      if (response.data.code === '0000') {
        alert('인증번호가 발송되었습니다. 휴대폰을 확인해주세요.');
      } else {
        alert('인증번호 발송에 실패했습니다. 다시 시도해주세요.');
      }
    } catch (error) {
      console.error('인증번호 발송 중 오류 발생:', error);
      alert('인증번호 발송 중 오류가 발생했습니다. 다시 시도해주세요.');
    }
  };

  const handleVerifyCode = async () => {
    if (!formData.verificationCode) {
      alert('인증번호를 입력해주세요.');
      return;
    }

    try {
      const response = await api.post('/deeps_user/api/v1/auth-check/', {
        hp: formData.phone,
        auth: formData.verificationCode
      });

      if (response.data.code === '0000') {
        alert('인증이 완료되었습니다.');
        setIsPhoneVerified(true);
      } else {
        alert('인증에 실패했습니다. 다시 시도해주세요.');
      }
    } catch (error) {
      console.error('인증 확인 중 오류 발생:', error);
      alert('인증 확인 중 오류가 발생했습니다. 다시 시도해주세요.');
    }
  };

  return (
    <div className="signup-container">
      <h2>회원가입</h2>
      <form onSubmit={handleSubmit}>
      <div className="form-group2">
          <label>가입자</label>
          <div className="custom-radio-group">
            <input
              type="radio"
              id="level0"
              name="level"
              value="0"
              checked={formData.level === '0' || formData.gender === ''}
              onChange={handleChange}
              className="custom-radio-input"
            />
            <label htmlFor="level0" className={`custom-radio-label ${formData.level === '0' ? 'active' : ''}`}>
              회원
            </label>
            <input
              type="radio"
              id="level1"
              name="level"
              value="1"
              checked={formData.level === '1'}
              onChange={handleChange}
              className="custom-radio-input"
            />
            <label htmlFor="level1" className={`custom-radio-label ${formData.level === '1' ? 'active' : ''}`}>
              강사
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>아이디 (필수)</label>
          <input
            type="text"
            name="userId"
            value={formData.userId}
            onChange={handleChange}
            placeholder="아이디를 입력해주세요"
            required
          />
        </div>
        <div className="form-group">
          <label>비밀번호 (필수)</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="비밀번호를 입력해주세요"
            required
          />
        </div>
        <div className="form-group">
          <label>비밀번호 확인 (필수)</label>
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            placeholder="비밀번호를 한번 더 입력해주세요"
            required
          />
        </div>
        <div className="form-group">
          <label>이름 (필수)</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="이름을 입력해주세요"
            required
          />
        </div>
        <div className="form-group">
          <label>이메일 (필수)</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="이메일을 입력해주세요"
            required
          />
        </div>
        <div className="form-group">
          <label>휴대폰 (필수)</label>
          <div className="input-button-group">
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="휴대폰 번호를 입력해 주세요"
              required
            />
            <button type="button" className="verification-button" onClick={handleSendVerification}>인증 번호 받기</button>
          </div>
        </div>
        <div className="form-group">
          <label></label>
          <div className="input-button-group">
            <input
              type="text"
              name="verificationCode"
              value={formData.verificationCode || ''}
              onChange={handleChange}
              placeholder="인증 번호를 입력해 주세요"
            />
            <button type="button" className="verification-button" onClick={handleVerifyCode}>인증 확인</button>
          </div>
        </div>
        {/* <div className="form-group">
          <label>주소 (필수)</label>
          <div className="input-button-group">
            <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="주소검색을 해주세요"
                required
              />
            <button type="button" className="address-search-button">주소검색</button>
          </div>
        </div>
        <div className="form-group">
        <label></label>
          <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              placeholder="상세주소를 입력해주세요"
              required
            />
        </div> */}
        {/* <div className="form-group">
          <label>전화번호</label>
          <input
            type="text"
            name="phoneVerification"
            value={formData.phoneVerification}
            onChange={handleChange}
            placeholder="전화번호를 입력해 주세요"
          />
        </div> */}
        <div className="form-group2">
          <label>성별</label>
          <div className="custom-radio-group">
            <input
              type="radio"
              id="genderM"
              name="gender"
              value="M"
              checked={formData.gender === 'M' || formData.gender === '' }
              onChange={handleChange}
              className="custom-radio-input"
            />
            <label htmlFor="genderM" className={`custom-radio-label ${formData.gender === 'M' ? 'active' : ''}`}>
              남자
            </label>
            <input
              type="radio"
              id="genderF"
              name="gender"
              value="F"
              checked={formData.gender === 'F'}
              onChange={handleChange}
              className="custom-radio-input"
            />
            <label htmlFor="genderF" className={`custom-radio-label ${formData.gender === 'F' ? 'active' : ''}`}>
              여자
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>생년월일</label>
          <DayPicker
            mode="single"
            selected={selectedDate}
            onSelect={handleDateChange}
            locale={ko}
            fromYear={1900}
            toYear={new Date().getFullYear()}
            captionLayout="dropdown"
            footer={selectedDate ? `선택된 날짜: ${format(selectedDate, 'PPP', { locale: ko })}` : '날짜를 선택해주세요'}
          />
        </div>

        <div className="divdorgap"/>
        
        <div className="term-group">
          <label className="lblterm">이용약관동의</label>
          <div className="allAgree">
            <h3>
              <input
                type="checkbox"
                name="allAccepted"
                className="form-check-input"
                checked={formData.termsAccepted && formData.ageConfirmed && formData.privacyAccepted}
                onChange={(e) => {
                  const checked = e.target.checked;
                  setFormData({
                    ...formData,
                    termsAccepted: checked,
                    ageConfirmed: checked,
                    privacyAccepted: checked,
                  });
                }}
              />
              전체 동의 합니다.
            </h3>
            <div className="divadvise">※ 모든 약관동의는 필수 및 선택정보에 대한 동의도 포함되어 있으며, 선택항목에 동의를 거부하시는 경우에도 서비스는 이용 가능합니다.</div>
            </div>
        </div>
        <div className="term-group">
          <label className="lblterm"></label>
          <div className="allAgree">
            <span className="spanover">
              <input
                  type="checkbox"
                  name="allAccepted"
                  className="form-check-input"
                  checked={formData.ageConfirmed}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setFormData({
                      ...formData,
                      ageConfirmed: checked,
                    });
                  }}
                />
                <label className="lblover">본인은 만 14세 이상입니다.</label>
            </span>
          </div>
        </div>

        <div className="term-group">
          <label className="lblterm"></label>
          <div className="allAgree">
            <span className="spanover">
              <input
                  type="checkbox"
                  name="allAccepted"
                  className="form-check-input"
                  checked={formData.termsAccepted}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setFormData({
                      ...formData,
                      termsAccepted: checked,
                    });
                  }}
                />
                <label className="lblover">이용약관 동의(필수)</label>
                <label className="lbldetail" onClick={() => openTermsModal(1)}>상세보기</label>
            </span>
          </div>
        </div>

        <div className="term-group">
          <label className="lblterm"></label>
          <div className="allAgree">
            <span className="spanover">
              <input
                  type="checkbox"
                  name="allAccepted"
                  className="form-check-input"
                  checked={formData.privacyAccepted}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setFormData({
                      ...formData,
                      privacyAccepted: checked,
                    });
                  }}
                />
                <label className="lblover">개인정보 수집및 이용동의(필수)</label>
                <label className="lbldetail" onClick={() => openTermsModal(2)}>상세보기</label>
            </span>
          </div>
        </div>


        <button type="submit" className="signup-button">동의하고 가입하기</button>
      </form>
      <Modal
        isOpen={isTermsModalOpen}
        onRequestClose={closeTermsModal}
        className="modern-modal terms-modal"
        overlayClassName="modern-modal-overlay"
      >
        <div className="modern-modal-content">
          <div className="modern-modal-header">
            <h2>{modalTitle}</h2>
            <button className="modern-close-button" onClick={closeTermsModal}>
              <svg viewBox="0 0 24 24">
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
              </svg>
            </button>
          </div>
          <div className="modern-modal-body">
            <pre>{termsContent}</pre>
          </div>
          <div className="modern-modal-footer">
            <button onClick={closeTermsModal} className="modern-button">
              확인
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isSuccessModalOpen}
        onRequestClose={closeSuccessModal}
        contentLabel="회원가입 성공"
        className="modern-modal success-modal"
        overlayClassName="modern-modal-overlay"
      >
        <div className="modern-modal-content">
          <div className="modern-modal-header">
            <h2>회원가입 성공!</h2>
            <button className="modern-close-button" onClick={closeSuccessModal}>
              <svg viewBox="0 0 24 24">
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
              </svg>
            </button>
          </div>
          <div className="modern-modal-body">
            <p>DEEPS의 회원이 되신 것을 환영합니다. 이제 다양한 서비스를 이용하실 수 있습니다.</p>
          </div>
          <div className="modern-modal-footer">
            <button onClick={closeSuccessModal} className="modern-button">메인 페이지로 이동</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

// Custom input component to prevent keyboard from showing up on mobile
const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <button type="button" className="form-control" onClick={onClick} ref={ref}>
    {value || "생년월일을 선택해주세요"}
  </button>
));

export default SignupPage;
