import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './PaymentResult.css';

const PaymentResult = ({ setUserId }) => {
  const [paymentResult, setPaymentResult] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const result = searchParams.get('result');

    if (result === 'success') {
      setPaymentResult({ success: true, message: '결제가 성공적으로 완료되었습니다. 구매 내용은 사용자 아이디를 클릭하여 구매 목록 아이콘에서 확인하실 수 있습니다.' });
    } else if (result === 'fail') {
      setPaymentResult({ success: false, message: '결제에 실패했습니다.' });
    } else {
      setPaymentResult({ success: false, message: '알 수 없는 결제 결과입니다.' });
    }

    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, [location, setUserId]);

  const handleHomeClick = () => {
    navigate('/');
  };

  if (!paymentResult) {
    return (
      <div className="payment-result-container">
        <div className="result-card">
          <h2 className="result-title">결제 결과를 확인 중입니다...</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="payment-result-container">
      <div className="result-card">
        <div className={`result-icon ${paymentResult.success ? 'success' : 'failure'}`}>
          {paymentResult.success ? '✅' : '❌'}
        </div>
        <h2 className="result-title">
          {paymentResult.success ? '결제 성공' : '결제 실패'}
        </h2>
        <p className="result-message">{paymentResult.message}</p>
        <button className="home-button" onClick={handleHomeClick}>홈으로 이동</button>
      </div>
    </div>
  );
};

export default PaymentResult;