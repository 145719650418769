import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './common.css';
import './SportsLearning.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import api from '../utils/api';

const SportsLearning = () => {
  const [selectedDates, setSelectedDates] = useState([new Date()]);
  const [clickedDate, setClickedDate] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [level, setLevel] = useState(0);
  const navigate = useNavigate();

  // 새로운 상태 추가
  const [sports, setSports] = useState([]);
  const [courses, setCourses] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [locations, setLocations] = useState([]);

  // 선택된 필터 상태 추가
  const [selectedSport, setSelectedSport] = useState('전체');
  const [selectedCourse, setSelectedCourse] = useState('전체');
  const [selectedInstructor, setSelectedInstructor] = useState('전체');
  const [selectedLocation, setSelectedLocation] = useState('전체');

  // 팝업 관련 상태 추가
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');


  const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;

  useEffect(() => {
    fetchInitialData();
    fetchSchedules();
  }, []);

  const fetchInitialData = async () => {
    try {
      const sportsResponse = await api.get(`${API_BASE_PATH}/sports`);
      setSports(['전체', ...sportsResponse.data]);

      const coursesResponse = await api.get(`${API_BASE_PATH}/courses`);
      setCourses(['전체', ...coursesResponse.data.courses]);
      setInstructors(['전체', ...coursesResponse.data.instructors]);
      setLocations(['전체', ...coursesResponse.data.locations]);

      // 스케줄 가져오기
      const schedulesResponse = await api.get(`${API_BASE_PATH}/sports-courses`);
      setSchedules(schedulesResponse.data);

      // 선택 가능한 날짜 업데이트
      const dates = schedulesResponse.data.map(schedule => {
        const [year, month, day] = schedule.date.split('-');
        return new Date(year, month - 1, day);
      });
      setSelectedDates(dates);
    } catch (error) {
      console.error('초기 데이터 가져오기 실패:', error);
    }
  };

  useEffect(() => {
    console.log("Selected dates updated:", selectedDates);
  }, [selectedDates]);

  const handleSportChange = async (e) => {
    const sport = e.target.value;
    setSelectedSport(sport);
    if (sport !== '전체') {
      await fetchCourses(sport);
      setSelectedCourse('전체');
      setSelectedInstructor('전체');
      setSelectedLocation('전체');
    } else {
      await fetchInitialData();
    }
    fetchSchedules(sport, '전체', '전체', '전체');
  };

  const handleCourseChange = async (e) => {
    const course = e.target.value;
    setSelectedCourse(course);
    if (course !== '전체') {
      await fetchInstructors(selectedSport, course);
      setSelectedInstructor('전체');
      setSelectedLocation('전체');
    } else {
      await fetchInstructors(selectedSport);
    }
    fetchSchedules(selectedSport, course, '전체', '전체');
  };

  const handleInstructorChange = async (e) => {
    const instructor = e.target.value;
    setSelectedInstructor(instructor);
    if (instructor !== '전체') {
      await fetchLocations(selectedSport, selectedCourse, instructor);
      setSelectedLocation('전체');
    } else {
      await fetchLocations(selectedSport, selectedCourse);
    }
    fetchSchedules(selectedSport, selectedCourse, instructor, '전체');
  };

  const handleLocationChange = (e) => {
    const location = e.target.value;
    setSelectedLocation(location);
    fetchSchedules(selectedSport, selectedCourse, selectedInstructor, location);
  };

  const fetchCourses = async (sport) => {
    try {
      const response = await api.get(`${API_BASE_PATH}/courses`, { params: { sport } });
      setCourses(['전체', ...response.data.courses]);
      setInstructors(['전체', ...response.data.instructors]);
      setLocations(['전체', ...response.data.locations]);
    } catch (error) {
      console.error('과정 및 관련 데이터 가져오기 실패:', error);
    }
  };

  const fetchInstructors = async (sport, course) => {
    try {
      const response = await api.get(`${API_BASE_PATH}/instructors`, { params: { sport, course } });
      setInstructors(['전체', ...response.data.instructors]);
      setLocations(['전체', ...response.data.locations]);
    } catch (error) {
      console.error('강사 및 장소 가져오기 실패:', error);
    }
  };

  const fetchLocations = async (sport, course, instructor) => {
    try {
      const response = await api.get(`${API_BASE_PATH}/locations`, { params: { sport, course, instructor } });
      setLocations(['전체', ...response.data]);
    } catch (error) {
      console.error('장소 가져오기 실패:', error);
    }
  };

  const handleDateClick = (date) => {
    if (selectedDates.some(selectedDate => selectedDate.getTime() === date.getTime())) {
      console.log("handleDateClick - Date clicked:", date);
      setClickedDate(prevDate => {
        const newDate = prevDate && prevDate.getTime() === date.getTime() ? null : date;
        console.log("handleDateClick - Previous Clicked Date:", prevDate);
        console.log("handleDateClick - Updating Clicked Date to:", newDate);
        return newDate;
      });
    } else {
      console.log("handleDateClick - This date is not selectable:", date);
    }
  };

  useEffect(() => {
    const userLevel = localStorage.getItem("level");
    setLevel(parseInt(userLevel) || 0);
  }, []);

  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      console.log("tileClassName - Processing date:", date);

      const day = date.getDay();

      if (clickedDate && clickedDate.getTime() === date.getTime()) {
        console.log("tileClassName - Applying 'clicked-date' class to:", date);
        return 'clicked-date';
      }

      if (selectedDates.some(selectedDate => selectedDate.getTime() === date.getTime())) {
        console.log("tileClassName - Applying 'custom-selected-date' class to:", date);
        return 'custom-selected-date';
      }

      if (day === 0) {
        console.log("tileClassName - Applying 'sunday' class to:", date);
        return 'sunday';
      } else if (day === 6) {
        console.log("tileClassName - Applying 'saturday' class to:", date);
        return 'saturday';
      }

      console.log("tileClassName - No special class applied for:", date);
    }

    return null;
  };

  const handleIconClick = () => {
    navigate('/input');
  };

  const handleScheduleClick = (schedule) => {
    const userLevel = parseInt(localStorage.getItem("level")) || 0;
    const allowedLevels = schedule.allowed_user_levels || [];

    if (userLevel < Math.max(...allowedLevels)) {
      setPopupMessage("수강 레벨이 맞지 않습니다.");
      setShowPopup(true);
      return;
    }

    const applyParticipantsCount = schedule.apply_participants.length;
    const waitParticipantsCount = schedule.wait_participants.length;
    const totalParticipants = schedule.total_participants;
    const practiceParticipantsCount = schedule.practice_participants.length;
    const totalPracticeParticipants = schedule.total_practice_participants

    const lessonInfo = `강습: ${applyParticipantsCount} / ${totalParticipants} (대기: ${waitParticipantsCount})`;
    const practiceInfo = `자율: ${practiceParticipantsCount} / ${totalPracticeParticipants} (대기: 0)`;

    navigate(`/schedule/${schedule.id}`, { state: { schedule, lessonInfo, practiceInfo } });
  };

  const fetchSchedules = async (sport, course, instructor, location) => {
    try {
      const response = await api.get(`${API_BASE_PATH}/sports-courses`, {
        params: {
          sport: sport !== '전체' ? sport : '전체',
          course: course !== '전체' ? course : '전체',
          instructor: instructor !== '전체' ? instructor : '전체',
          location: location !== '전체' ? location : '전체',
        }
      });
      setSchedules(response.data);

      const dates = response.data.map(schedule => {
        const [year, month, day] = schedule.date.split('-');
        return new Date(year, month - 1, day);
      });
      setSelectedDates(dates);
    } catch (error) {
      console.error('스케줄 가져오기 실패:', error);
    }
  };

  // 팝업을 닫는 함수
  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="main-container">
      <div className="title-container">
        <h2 className="section-title">스포츠 학습</h2>
      </div>
      {level > 10 && (
        <div className="icon-container">
          <i className="fas fa-edit title-icon" onClick={handleIconClick}></i>
        </div>
      )}

      <div className="filters">
        <div className="filter-row">
          <div className="filter-item">
            <label>스포츠 종목</label>
            <select 
              className="custom-select" 
              value={selectedSport} 
              onChange={handleSportChange}
            >
              {sports.map((sport, index) => (
                <option key={index} value={sport}>{sport}</option>
              ))}
            </select>
          </div>
          <div className="filter-item">
            <label>과정</label>
            <select 
              className="custom-select"
              value={selectedCourse}
              onChange={handleCourseChange}
            >
              {courses.map((course, index) => (
                <option key={index} value={course}>{course}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="filter-row">
          <div className="filter-item">
            <label>강사</label>
            <select 
              className="custom-select"
              value={selectedInstructor}
              onChange={handleInstructorChange}
            >
              {instructors.map((instructor, index) => (
                <option key={index} value={instructor}>{instructor}</option>
              ))}
            </select>
          </div>
          <div className="filter-item">
            <label>장소</label>
            <select 
              className="custom-select"
              value={selectedLocation}
              onChange={handleLocationChange}
            >
              {locations.map((location, index) => (
                <option key={index} value={location}>{location}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="calendar-container">
        <Calendar
          value={selectedDates}
          onClickDay={handleDateClick}
          tileClassName={tileClassName}
          className="custom-calendar"
          locale="ko-KR"
        />
      </div>
      <div className="schedule-container">
        <div className="schedule-list">
          {schedules.map(schedule => {
            const applyParticipantsCount = schedule.apply_participants.length;
            const waitParticipantsCount = schedule.wait_participants.length;
            const totalParticipants = schedule.total_participants;
            const practiceParticipantsCount = schedule.practice_participants.length;
            const totalPracticeParticipants = schedule.total_practice_participants
            const practiceWaitParticipantsCount = schedule.practice_wait_participants.length;

            return (
              <div key={schedule.id} className="schedule-item" onClick={() => handleScheduleClick(schedule)}>
                <div className="schedule-item-row">
                  <h4>
                    {schedule.course}
                    {schedule.day >= 1 && `(Days${schedule.day})`}
                    {schedule.is_theory && "(이론)"}
                  </h4>
                  <p className="date-time">{schedule.date} {schedule.time}</p>
                </div>
                <div className="schedule-item-row">
                  <p className="instructor">{schedule.instructor.name} 강사</p>
                  <p className="schedule-details">
                    강습: {applyParticipantsCount} / {totalParticipants} (대기: {waitParticipantsCount}) | 자율: {practiceParticipantsCount} / {totalPracticeParticipants} (대기: {practiceWaitParticipantsCount})
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <p>{popupMessage}</p>
            <button onClick={closePopup}>확인</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SportsLearning;