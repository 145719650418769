import React, { useState, useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './common.css';
import './TravelProductInput.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import api from '../utils/api';
import { useLocation, useNavigate } from 'react-router-dom';

const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;

const TravelProductsInput = (setUserId) => {
  const [messageContent, setMessageContent] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    start_date: new Date(),
    end_date: new Date(),
    location: '',
    guide: '',
    requirements: '',
    max_participants: 1,
    price: 0,
    detailed_content: ''
  });

  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image', 'video'],
        ['clean']
      ]
    },
    formats: ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'link', 'image', 'video'],
    theme: 'snow',
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get('productId');

  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        setFormData(prevState => ({
          ...prevState,
          detailed_content: quill.root.innerHTML
        }));
      });
    }
  }, [quill]);

  useEffect(() => {
    if (productId && quill) {
      const fetchProductData = async () => {
        try {
          const response = await api.get(`${API_BASE_PATH}/get-travel-product/${productId}/`);
          const productData = response.data;
          
          setFormData({
            name: productData.name,
            start_date: new Date(productData.start_date),
            end_date: new Date(productData.end_date),
            location: productData.location,
            guide: productData.guide,
            requirements: productData.requirements,
            max_participants: productData.max_participants,
            price: productData.price,
            detailed_content: productData.detailed_content
          });
          
          quill.root.innerHTML = productData.detailed_content;
        } catch (error) {
          console.error('여행 상품 데이터 가져오기 실패:', error);
        }
      };

      fetchProductData();
    }
  }, [productId, quill]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleDateChange = (date, field) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: date
    }));
  };

  const handleSave = async () => {
    if (quill) {
      const content = quill.root.innerHTML;

      // 날짜를 YYYY-MM-DD 형식으로 변환
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.name);
      formDataToSend.append('start_date', formatDate(formData.start_date));
      formDataToSend.append('end_date', formatDate(formData.end_date));
      formDataToSend.append('location', formData.location);
      formDataToSend.append('guide', formData.guide);
      formDataToSend.append('requirements', formData.requirements);
      formDataToSend.append('max_participants', formData.max_participants);
      formDataToSend.append('price', formData.price);
      formDataToSend.append('detailed_content', content);

      try {
        let response;
        if (productId) {
          // 기존 상품 수정
          response = await api.put(`${API_BASE_PATH}/update-travel-product/${productId}/`, formDataToSend, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          setMessageContent('여행 상품이 성공적으로 수정되었습니다.');
        } else {
          // 새 상품 생성
          response = await api.post(`${API_BASE_PATH}/save-travel-product/`, formDataToSend, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          setMessageContent('새로운 여행 상품이 성공적으로 저장되었습니다.');
        }
        setShowMessage(true);
      } catch (error) {
        console.error('상품 저장 중 오류 발생:', error);
        if (error.response) {
          switch (error.response.status) {
            case 400:
              setMessageContent('입력 데이터가 올바르지 않습니다. 다시 확인해주세요.');
              break;
            case 401:
              setMessageContent('인증에 실패했습니다. 다시 로그인해주세요.');
              localStorage.removeItem('userId');
              setUserId(null);
              break;
            case 500:
              if (error.response.data.code === "1007") {
                setMessageContent('서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
              } else {
                setMessageContent('알 수 없는 오류가 발생했습니다.');
              }
              break;
            default:
              setMessageContent('상품 저장 중 오류가 발생했습니다.');
          }
        } else {
          setMessageContent('네트워크 오류가 발생했습니다. 인터넷 연결을 확인해주세요.');
        }
        setShowMessage(true);
      }
    }
  };

  return (
    <div className="travelproduct-input-container">
      <h2 className="travelproduct-section-title">여행 상품 입력</h2>

      <div className="travelproduct-form-group">
        <div className="travelproduct-input-row">
          <div className="travelproduct-input-item">
            <label>여행 상품</label>
            <input type="text" name="name" value={formData.name} onChange={handleInputChange} className="travelproduct-form-control" />
          </div>
          <div className="travelproduct-input-item">
            <label>장     소</label>
            <input type="text" name="location" value={formData.location} onChange={handleInputChange} className="travelproduct-form-control" />
          </div>
        </div>

        <div className="travelproduct-input-row">
          <div className="travelproduct-input-item">
            <label>시작 날짜</label>
            <DatePicker
              selected={formData.start_date}
              onChange={(date) => handleDateChange(date, 'start_date')}
              className="travelproduct-form-control"
              dateFormat="yyyy.MM.dd"
            />
          </div>
          <div className="travelproduct-input-item">
            <label>종료 날짜</label>
            <DatePicker
              selected={formData.end_date}
              onChange={(date) => handleDateChange(date, 'end_date')}
              className="travelproduct-form-control"
              dateFormat="yyyy.MM.dd"
            />
          </div>
        </div>

        <div className="travelproduct-input-row">
          <div className="travelproduct-input-item">
            <label>인  솔  자</label>
            <input type="text" name="guide" value={formData.guide} onChange={handleInputChange} className="travelproduct-form-control" />
          </div>
          <div className="travelproduct-input-item">
            <label>자격요건</label>
            <input type="text" name="requirements" value={formData.requirements} onChange={handleInputChange} className="travelproduct-form-control" />
          </div>
        </div>

        <div className="travelproduct-input-row">
          <div className="travelproduct-input-item">
            <label>최대인원</label>
            <select name="max_participants" value={formData.max_participants} onChange={handleInputChange} className="travelproduct-form-control">
              {[...Array(10)].map((_, i) => (
                <option key={i} value={i + 1}>{i + 1}</option>
              ))}
            </select>
          </div>
          <div className="travelproduct-input-item">
            <label>비용(원)</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
              className="travelproduct-form-control"
              placeholder="금액을 입력하세요"
            />
          </div>
        </div>

        <div className="travelproduct-form-group-detailed">
          <label>상세 내용</label>
          <div>
            <div ref={quillRef} />
          </div>
        </div>

        <div className="travelproduct-div-input-save">
          <button className="sportsinput-btn btn-primary save-button" onClick={handleSave}>저장</button>
        </div>
      </div>
      {showMessage && (
        <div className="message-box">
          <p>{messageContent}</p>
          <button className="messagebox-close-button" onClick={() => setShowMessage(false)}>
            닫기
          </button>
      </div>
      )}
    </div>
  );
};

export default TravelProductsInput;